import { useEffect, useRef, useState } from "react";
import "./styles.css";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import FooterMenu from "../../components/FooterMenu";
import PatientNameOrder from "../../components/PatientNameOrder";
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Select from "../../components/Select";
import Loading from "../../components/Loading";
import NoOrders from "../../components/NoOrders";
import { CiTrash } from "react-icons/ci";
import UploadFile from "../../components/UploadFile/UploadFile";
import { IoIosArrowDown } from "react-icons/io";

interface Item {
  item: string;
  quantity: number;
}

interface Surgery {
  user_historico: string;
  user_laudo: string;
  opme_list: Item[];
  user_email: string;
  timestamp: string;
  procedimentos_validados: string;
  procedimentos_modelo: string;
  user_name: string;
  pedido_cirurgia: string;
}

const OrdersPage: React.FC = () => {
  const ROTA = "https://api.letsdoc.ai";
  //const ROTA = "http://localhost:5000";

  const location = useLocation();
  const currentPath = location.pathname;
  const [isNameFocused, setIsNameFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [indexSurgerie, setIndexSurgery] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const getToken = () => {
    const token = localStorage.getItem("authToken");
    return token;
  };
  const token = getToken();
  const { user } = useAuth0();
  const EMAIL = user?.email;
  const [showPlanSelection, setShowPlanSelection] = useState(false);
  const [showOPMEPlanSelection, setShowOPMEPlanSelection] = useState(false);
  const [status, setStatus] = useState(1);
  const [statusName, setStatusName] = useState("");

  const [isAtBottom, setIsAtBottom] = useState(false);
  const ordersPageContentRef = useRef<HTMLDivElement | null>(null);
  const [hasOverflow, setHasOverflow] = useState(false);

  const [patientsByDoctor, setPatientsByDoctor] = useState<Array<string>>([]);
  const [surgeriesByPatient, setSurgeriesByPatient] = useState<Array<Surgery>>(
    []
  );
  const [requesting, setRequesting] = useState<Boolean>(false);
  const [surgerie, setSurgerie] = useState<Surgery>();

  const [informacoes, setInformacoes] = useState({
    nomePaciente: "",
    descricaoPaciente: "",
    procedimentos: "",
    cids: "",
    dataEmissao: "",
    hospital: "",
  });
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  function formatarProcedimentos(procedimentos: string): string {
    const linhasProcedimentos = procedimentos.split("\n");

    const procedimentosCorrigidos = linhasProcedimentos.map((linha) => {
      const match = linha.match(/^(\d+)\s*-?\s*x?(\d+)\s*-?\s*(.*)$/);

      if (match) {
        const codigo = match[1];
        const quantidade = match[2];
        const descricao = match[3];
        return `${codigo} x${quantidade} ${descricao}`;
      } else {
        return linha;
      }
    });

    return procedimentosCorrigidos.join("\n");
  }

  useEffect(() => {
    if (surgerie) {
      const pedidoSplit = surgerie.pedido_cirurgia.split(
        "-------------------------------------------------"
      );
      const pedidoCirurgia = surgerie.pedido_cirurgia;
      const nomePacienteMatch = pedidoCirurgia.match(
        /Nome do paciente:\s*(.*)/
      );

      let procedimentosCorrigidos = formatarProcedimentos(
        pedidoSplit[3]?.split(":")[1]?.trim()
      );

      setInformacoes({
        nomePaciente: nomePacienteMatch ? nomePacienteMatch[1].trim() : "",
        descricaoPaciente: pedidoSplit[2]?.split(":")[1]?.trim() || "",
        procedimentos: procedimentosCorrigidos || "",
        cids: pedidoSplit[4]?.split(":")[1]?.trim() || "",
        dataEmissao: pedidoSplit[5]?.split(":")[1]?.trim() || "",
        hospital: pedidoSplit[6]?.split(":")[1]?.trim() || "",
      });
    }
  }, [surgerie]);

  const [isDownloading, setIsDownloading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<string>("Selecione o plano");
  const [selectedDownload, setSelectedDownload] = useState<string>(
    "Selecione o documento"
  );
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [showOptionsPlan, setShowOptionsPlan] = useState<boolean>(false);
  const [showOptionsDownload, setShowOptionsDownload] =
    useState<boolean>(false);
  const [showSelectForDownload, setShowSelectForDownload] =
    useState<boolean>(false);

  const calculateTextareaRows = (
    text: string | any,
    charPerRow: number
  ): number => {
    const normalizedText = typeof text === "string" ? text : String(text);

    const lines = normalizedText.split("\n").length;
    const additionalLines = Math.ceil(normalizedText.length / charPerRow);

    return Math.max(lines, additionalLines);
  };

  const handlePlanSelection = (plan: string) => {
    setSelectedPlan(plan);
    setShowOptionsPlan(false);
  };
  const handleDownloadSelection = (document: string) => {
    setSelectedDownload(document);
    setShowOptionsDownload(false);
    if (document === "PEDIDO_DE_CIRURGIA" || document === "OPME") {
      setShowSelectForDownload(true);
    } else {
      setShowSelectForDownload(false);
      setSelectedPlan("Selecione o plano");
    }
  };

  useEffect(() => {
    return () => {
      setSelectedFile(null);
    };
  }, []);

  let patientsByDoctorNotSorted: string[] = [];

  const toSort = async (arr: any) => {
    patientsByDoctorNotSorted = arr;
    patientsByDoctorNotSorted.sort();
  };

  const deleteSurgeryOrder = async () => {
    try {
      const response = await fetch(
        `${ROTA}/paciente/${EMAIL}/${statusName}/${indexSurgerie}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Erro ao deletar a cirurgia.");
      }
    } catch (error: any) {
      console.error("Erro ao deletar a cirurgia:", error);
      alert(error.message);
    }

    window.location.reload();
    back();
  };

  const getPatientsByDoctor = async () => {
    const URL = `${ROTA}/pacientes/${EMAIL}`;
    setRequesting(true);
    await fetch(URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro na requisição");
        }
        return response.json();
      })
      .then(async (data) => {
        await toSort(data.unique_patients);
        setPatientsByDoctor(patientsByDoctorNotSorted);
      })
      .catch((error) => {
        console.error("Houve um problema com a requisição:", error);
      });
    setRequesting(false);
  };

  const getSurgersByPatient = async () => {
    const URL = `${ROTA}/paciente/${EMAIL}/${statusName}`;
    setRequesting(true);
    await fetch(URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro na requisição");
        }
        return response.json();
      })
      .then((data) => {
        setSurgeriesByPatient(data.surgeries);
      })
      .catch((error) => {
        console.error("Houve um problema com a requisição:", error);
      });
    setRequesting(false);
  };

  useEffect(() => {
    if (status === 1) {
      getPatientsByDoctor();
    } else if (status === 2) {
      getSurgersByPatient();
    }
  }, [status]);

  useEffect(() => {
    const handleScroll = () => {
      const element = ordersPageContentRef.current;

      if (element) {
        if (
          element.scrollHeight - element.scrollTop <=
          element.clientHeight + 1
        ) {
          setIsAtBottom(true);
        } else {
          setIsAtBottom(false);
        }
      }
    };

    const element = ordersPageContentRef.current;

    if (element) {
      element.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, [isAtBottom, status]);

  useEffect(() => {
    const checkOverflow = () => {
      if (ordersPageContentRef.current) {
        const isOverflowing =
          ordersPageContentRef.current.scrollHeight >
          ordersPageContentRef.current.clientHeight;
        setHasOverflow(isOverflowing);
      }
    };

    const observeContentChanges = () => {
      const observer = new MutationObserver(() => {
        checkOverflow();
      });

      if (ordersPageContentRef.current) {
        observer.observe(ordersPageContentRef.current, {
          childList: true,
          subtree: true,
        });
      }

      return () => observer.disconnect();
    };

    const timeout = setTimeout(() => {
      checkOverflow();
    }, 100);

    const disconnectObserver = observeContentChanges();

    window.addEventListener("resize", checkOverflow);

    return () => {
      clearTimeout(timeout);
      disconnectObserver();
      window.removeEventListener("resize", checkOverflow);
    };
  }, [status]);

  const scrollToBottom = () => {
    if (ordersPageContentRef.current) {
      ordersPageContentRef.current.scrollTo({
        top: ordersPageContentRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const filteredNames = patientsByDoctor?.filter((name) =>
    name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const firstProcedures = surgeriesByPatient
    .map((surgery: { procedimentos_validados: string; timestamp: string }) => {
      const procedimentos = surgery.procedimentos_validados.split(" ");
      return procedimentos[1];
    })
    .sort();

  const filteredNamesOrders = firstProcedures?.filter((order) =>
    order.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const showOrders = (name: string) => {
    setStatus(status + 1);
    setStatusName(name);
    setHasOverflow(false);
    setIsAtBottom(false);
  };

  const showOrder = (index: any) => {
    setStatus(status + 1);
    setSurgerie(surgeriesByPatient[index]);
    setIndexSurgery(index);
    setHasOverflow(false);
    setIsAtBottom(false);
  };

  const back = () => {
    setStatus(status - 1);
    setHasOverflow(false);
    setIsAtBottom(false);
  };

  const createRequestInit = (body: object) => ({
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });

  const downloadPDF = async (
    url: string,
    requestInit: RequestInit,
    fileName: string
  ) => {
    const response = await fetch(url, requestInit);
    const blob = await response.blob();
    const urlBlob = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = urlBlob;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const transformOpmeItems = (opmeList: Item[]) => {
    return opmeList.map((opme) => [opme.item, opme.quantity.toString()]);
  };

  const getProcedureDescriptions = (procedures: string) => {
    return procedures.split("\n").slice(1);
  };

  const handleDownloadOPME = async (s: Surgery) => {
    setIsDownloading(true);

    const transformedOpmeItems = transformOpmeItems(s.opme_list);
    const procedureDescriptions = getProcedureDescriptions(
      s.procedimentos_validados
    );

    let template = "";
    switch (selectedPlan) {
      case "BRADESCO":
        template = "bradesco_opme";
        break;
      case "AMIL":
        template = "amil_opme";
        break;
      case "UNIMED":
        template = "unimed_opme";
        break;
      case "SULAMERICA":
        template = "sulamerica_opme";
        break;
      case "PETROBRÁS":
        template = "petrobras_opme";
        break;
      case "LEVE SAÚDE":
        template = "levesaude_opme";
        break;
    }

    const requestInit2 = createRequestInit({
      email: EMAIL,
      template_type: template,
      procedures: procedureDescriptions,
      opme_data: transformedOpmeItems,
      pacientName: statusName,
    });

    try {
      await downloadPDF(
        `${ROTA}/chat/exportOPMEPDF`,
        requestInit2,
        `${statusName}_opme.pdf`
      );
    } catch (error) {
      console.error("Erro ao baixar PDF:", error);
    } finally {
      setIsDownloading(false);
    }
  };

  const handleDownloadPedido = async () => {
    setIsDownloading(true);

    const pedido_cirurgia = `
      Aqui está seu pedido de cirurgia:
      -------------------------------------------------
      Nome do paciente:
      ${informacoes.nomePaciente}
      -------------------------------------------------
      Descrição do paciente:
      ${informacoes.descricaoPaciente}
      -------------------------------------------------
      Procedimentos:
      ${informacoes.procedimentos}
      -------------------------------------------------
      CID's:
      ${informacoes.cids}
      -------------------------------------------------
      Data de emissão:
      ${informacoes.dataEmissao}
      -------------------------------------------------
      Hospital:
      ${informacoes.hospital}
      -------------------------------------------------
    `;

    const requestInit1 = createRequestInit({
      email: EMAIL,
      pedido_de_cirurgia: pedido_cirurgia,
      template_type: selectedPlan,
    });

    let template = "";
    switch (selectedPlan) {
      case "BRADESCO":
        template = "bradesco_opme";
        break;
      case "AMIL":
        template = "amil_opme";
        break;
      case "UNIMED":
        template = "unimed_opme";
        break;
      case "SULAMERICA":
        template = "sulamerica_opme";
        break;
      case "PETROBRÁS":
        template = "petrobras_opme";
        break;
      case "LEVESAUDE":
        template = "levesaude_opme";
        break;
    }

    try {
      await downloadPDF(
        `${ROTA}/chat/exportPDF`,
        requestInit1,
        `${statusName}_pedido_de_cirurgia.pdf`
      );
    } catch (error) {
      console.error("Erro ao baixar PDF:", error);
    } finally {
      setIsDownloading(false);
    }
  };

  const handleDownloadResumo = async (s: Surgery) => {
    setIsDownloading(true);

    const pedido_cirurgia = `
      Aqui está seu pedido de cirurgia:
      -------------------------------------------------
      Nome do paciente:
      ${informacoes.nomePaciente}
      -------------------------------------------------
      Descrição do paciente:
      ${informacoes.descricaoPaciente}
      -------------------------------------------------
      Procedimentos:
      ${informacoes.procedimentos}
      -------------------------------------------------
      CID's:
      ${informacoes.cids}
      -------------------------------------------------
      Data de emissão:
      ${informacoes.dataEmissao}
      -------------------------------------------------
      Hospital:
      ${informacoes.hospital}
      -------------------------------------------------
    `;

    const requestInit3 = createRequestInit({
      email: EMAIL,
      pedido_de_cirurgia: pedido_cirurgia,
      materiais_opme: [],
    });

    try {
      await downloadPDF(
        `${ROTA}/chat/resumo`,
        requestInit3,
        `${statusName}_resumo.pdf`
      );
    } catch (error) {
      console.error("Erro ao baixar PDF:", error);
    } finally {
      setIsDownloading(false);
    }
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const updatedText = e.target.value;
    const updatedList = updatedText.split("\n").map((itemText, index) => {
      const currentItem = surgerie?.opme_list[index];
      return {
        item: itemText,
        quantity: currentItem?.quantity,
      };
    });
  };

  const handleDeleteClick = () => {
    setModalOpen(true);
  };

  const confirmDelete = () => {
    deleteSurgeryOrder();
    console.log("Pedido de cirurgia deletado");
    setModalOpen(false);
  };

  const cancelDelete = () => {
    setModalOpen(false);
  };

  return (
    <>
      <div className="orders-page-container">
        <div className="orders_page_content">
          {status === 1 ? (
            <></>
          ) : (
            <div onClick={() => back()} className="arrow">
              <img
                id="arrow"
                src={`${process.env.PUBLIC_URL}/arrow.svg`}
                alt="arrow"
              />
            </div>
          )}
          {status === 3 ? (
            surgerie && (
              <div className="orders_page_complete_container">
                <div className="icon-content">
                  <div className="trash-button" onClick={handleDeleteClick}>
                    <CiTrash />
                  </div>

                  {modalOpen && (
                    <div className="orders-modal">
                      <div className="orders-modal-content">
                        <p>Tem certeza de que deseja excluir este pedido?</p>
                        <div className="orders-modal-actions">
                          <button
                            className="confirm-button"
                            onClick={confirmDelete}
                          >
                            Confirmar
                          </button>
                          <button
                            className="cancel-button"
                            onClick={cancelDelete}
                          >
                            Cancelar
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className="orders_page_complete"
                  ref={ordersPageContentRef}
                >
                  <label htmlFor="nomePaciente">Nome do Paciente:</label>
                  <div className="editable-box">
                    <textarea
                      id="nomePaciente"
                      rows={calculateTextareaRows(informacoes.nomePaciente, 40)}
                      value={informacoes.nomePaciente}
                      onChange={(e) =>
                        setInformacoes({
                          ...informacoes,
                          nomePaciente: e.target.value,
                        })
                      }
                    />
                  </div>

                  <label htmlFor="descricaoPaciente">
                    Descrição do Paciente:
                  </label>
                  <div className="editable-box">
                    <textarea
                      id="descricaoPaciente"
                      rows={calculateTextareaRows(
                        informacoes.descricaoPaciente,
                        40
                      )}
                      value={informacoes.descricaoPaciente}
                      onChange={(e) =>
                        setInformacoes({
                          ...informacoes,
                          descricaoPaciente: e.target.value,
                        })
                      }
                    />
                  </div>

                  <label htmlFor="procedimentos">Procedimentos:</label>
                  <div className="editable-box">
                    <textarea
                      id="procedimentos"
                      rows={calculateTextareaRows(
                        informacoes.procedimentos,
                        40
                      )}
                      value={informacoes.procedimentos}
                      onChange={(e) =>
                        setInformacoes({
                          ...informacoes,
                          procedimentos: e.target.value,
                        })
                      }
                    />
                  </div>

                  <label htmlFor="cids">CID's:</label>
                  <div className="editable-box">
                    <textarea
                      id="cids"
                      rows={calculateTextareaRows(informacoes.cids, 40)}
                      value={informacoes.cids}
                      onChange={(e) =>
                        setInformacoes({
                          ...informacoes,
                          cids: e.target.value,
                        })
                      }
                    />
                  </div>

                  <label htmlFor="dataEmissao">Data de Emissão:</label>
                  <div className="editable-box">
                    <textarea
                      id="dataEmissao"
                      rows={calculateTextareaRows(informacoes.dataEmissao, 40)}
                      value={informacoes.dataEmissao}
                      onChange={(e) =>
                        setInformacoes({
                          ...informacoes,
                          dataEmissao: e.target.value,
                        })
                      }
                    />
                  </div>
                  <label htmlFor="hospital">Hospital:</label>
                  <div className="editable-box">
                    <textarea
                      id="hospital"
                      rows={calculateTextareaRows(informacoes.hospital, 40)}
                      value={informacoes.hospital}
                      onChange={(e) =>
                        setInformacoes({
                          ...informacoes,
                          hospital: e.target.value,
                        })
                      }
                    />
                  </div>
                  <label htmlFor="OPME">OPME's escolhidas:</label>
                  <div className="list_opmes">
                    {surgerie.opme_list.length > 0 ? (
                      <div className="editable-box">
                        <textarea
                          rows={calculateTextareaRows(
                            surgerie.opme_list
                              .map((opme) => opme.item)
                              .join("\n"),
                            40
                          )}
                          value={surgerie.opme_list
                            .map((opme) => opme.item)
                            .join("\n")}
                          onChange={handleTextareaChange}
                        />
                      </div>
                    ) : (
                      <p>Nenhum</p>
                    )}
                  </div>
                </div>

                <div className="orders-page-container">
                  <div>
                    <button
                      className="orders-page-download-button"
                      onClick={() => setShowPlanSelection(true)}
                    >
                      Guia de internação
                    </button>
                  </div>
                  {showPlanSelection && (
                    <div
                      className="orders-modal-overlay"
                      style={{
                        overflow: "auto",
                      }}
                    >
                      <div className="orders-modal">
                        <button
                          className="orders-modal-close-button"
                          onClick={() => setShowPlanSelection(false)}
                        >
                          &times;
                        </button>
                        <h1>Escolha o plano de saúde</h1>
                        <div
                          className="download-page__selected"
                          onClick={() => setShowOptions(!showOptions)}
                        >
                          {selectedPlan}
                          <IoIosArrowDown color="#4B73FF" />
                        </div>
                        <div
                          className={`download-page__options-container ${
                            showOptions ? "active" : ""
                          }`}
                        >
                          {[
                            "AMIL",
                            "BRADESCO",
                            "CAC",
                            "CASSI",
                            "EMBRATEL",
                            "EVERCROSS",
                            "GAMA",
                            "GEAP",
                            "GOLDEN_CROSS",
                            "INTERMEDICA",
                            "LEVESAUDE",
                            "MUTUA",
                            "OMINT",
                            "PASAVALE",
                            "PETROBRAS",
                            "SULAMERICA",
                            "UNIMED",
                          ].map((plan) => (
                            <div
                              key={plan}
                              className="download-page__option"
                              onClick={() => handlePlanSelection(plan)}
                            >
                              <input
                                type="radio"
                                className="download-page__radio"
                                id={plan}
                                name="category"
                                checked={selectedPlan === plan}
                                readOnly
                              />
                              <label htmlFor={plan}>{plan}</label>
                            </div>
                          ))}
                        </div>
                        <button
                          className="orders-page-download-button"
                          onClick={handleDownloadPedido}
                        >
                          {isDownloading ? (
                            <div className="loading-circle"></div>
                          ) : (
                            "Download"
                          )}
                        </button>
                      </div>
                    </div>
                  )}
                  <div className="orders-page-resumo-button">
                    <button onClick={() => handleDownloadResumo(surgerie)}>
                      {isDownloading ? (
                        <div className="loading-circle-resumo"></div>
                      ) : (
                        "Resumo"
                      )}
                    </button>
                  </div>

                  <button
                    className="orders-page-opme-button"
                    onClick={() => setShowOPMEPlanSelection(true)}
                  >
                    OPME
                  </button>
                  {showOPMEPlanSelection && (
                    <div className="orders-modal-overlay">
                      <div className="orders-modal">
                        <button
                          className="orders-modal-close-button"
                          onClick={() => setShowOPMEPlanSelection(false)}
                        >
                          &times;
                        </button>
                        <h1>Escolha o plano de saúde</h1>
                        <div
                          className="download-page__selected"
                          onClick={() => setShowOptions(!showOptions)}
                        >
                          {selectedPlan}
                          <IoIosArrowDown color="#4B73FF" />
                        </div>
                        <div
                          className={`download-page__options-container ${
                            showOptions ? "active" : ""
                          }`}
                        >
                          {[
                            "AMIL",
                            "LEVESAUDE",
                            "PETROBRAS",
                            "SULAMERICA",
                            "UNIMED",
                          ].map((plan) => (
                            <div
                              key={plan}
                              className="download-page__option"
                              onClick={() => handlePlanSelection(plan)}
                            >
                              <input
                                type="radio"
                                className="download-page__radio"
                                id={plan}
                                name="category"
                                checked={selectedPlan === plan}
                                readOnly
                              />
                              <label htmlFor={plan}>{plan}</label>
                            </div>
                          ))}
                        </div>
                        <button
                          className="orders-page-opme-button"
                          onClick={() => handleDownloadOPME(surgerie)}
                        >
                          {isDownloading ? (
                            <div className="loading-circle"></div>
                          ) : (
                            "Download"
                          )}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )
          ) : (
            <>
              <div className="orders_page_title">
                <h2>{status === 1 ? "Pedidos Salvos" : statusName}</h2>
              </div>
              <div className="orders_page_form">
                <div className="orders_page_input">
                  <div
                    className="orders_page_number"
                    style={{
                      color: isNameFocused ? "#4B73FF" : "#a9a9a9",
                    }}
                  >
                    <img
                      id="searching"
                      src={`${process.env.PUBLIC_URL}/search.svg`}
                      alt="searching"
                    />
                  </div>
                  <div className="orders_page_input_components">
                    <label
                      htmlFor="name"
                      style={{
                        top: isNameFocused ? "-18px" : "1px",
                        fontSize: isNameFocused ? "12px" : "15px",
                        color: isNameFocused ? "#4B73FF" : "#a9a9a9",
                      }}
                    >
                      {status === 1
                        ? "Busque por um paciente"
                        : "Busque por um pedido"}
                    </label>
                    <input
                      type="search"
                      id="name"
                      autoComplete="off"
                      onFocus={() => setIsNameFocused(true)}
                      onBlur={() => setIsNameFocused(false)}
                      style={{
                        borderColor: isNameFocused ? "#4B73FF" : "#a9a9a9",
                      }}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>

                <div className="orders_page_names" ref={ordersPageContentRef}>
                  {!requesting ? (
                    status === 1 ? (
                      patientsByDoctor?.length > 0 ? (
                        filteredNames?.map((name, index) => (
                          <PatientNameOrder
                            key={index}
                            name={name}
                            click={() => showOrders(name)}
                          />
                        ))
                      ) : (
                        <NoOrders name={"pedidos"} />
                      )
                    ) : firstProcedures.length > 0 ? (
                      filteredNamesOrders?.map((order, index) => (
                        <PatientNameOrder
                          key={index}
                          name={`Pedido - ${order}`}
                          click={() => showOrder(index)}
                        />
                      ))
                    ) : (
                      <NoOrders name={"cirurgias"} />
                    )
                  ) : (
                    <Loading />
                  )}
                </div>
              </div>
            </>
          )}
          <div
            className="orders_page_more"
            onClick={scrollToBottom}
            style={{
              transform: isAtBottom || !hasOverflow ? "scale(0)" : "scale(1)",
              opacity: isAtBottom || !hasOverflow ? 0 : 1,
              transition: "transform 0.3s ease, opacity 0.3s ease",
              willChange: "transform, opacity",
              pointerEvents: isAtBottom || !hasOverflow ? "none" : "auto",
              top: status === 3 ? "calc(100% - 22vh)" : "calc(100% - 14vh)",
              left: status === 3 ? "45%" : "50%",
            }}
          >
            <span className="material-symbols-outlined">
              arrow_downward_alt
            </span>
          </div>
        </div>
      </div>
      <div className="footer">
        <FooterMenu selectedButton={currentPath} />
      </div>
    </>
  );
};

export default OrdersPage;

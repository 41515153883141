import "./styles.css";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UploadFile from "../../components/UploadFile/UploadFile";
import Header from "../../components/Header";
import FooterMenu from "../../components/FooterMenu";
import { useAuth0 } from "@auth0/auth0-react";


const ConfigPage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;


      const handleGoHomeButton = () => {
        navigate("/novo-pedido");
      };
    

  return (
    <div className="config-page__main-container">
        <button className="config-page_arrow" onClick={handleGoHomeButton}>
            <img
                id="arrow"
                src={`${process.env.PUBLIC_URL}/arrow.svg`}
                alt="arrow"
                />        
        </button>
        <div className="resume-div">
        <UploadFile
          folder={"receituarios"}
          extension="pdf"
          errorMessage={"Insira apenas arquivo pdf!"}
          successMessage={"Resumo adicionado com sucesso!"}
          title={"Escolha seu receituário"}
        />
        <UploadFile
          folder={"carimbos"}
          extension="png,jpg,jpeg"
          errorMessage={"Insira apenas arquivo png!"}
          successMessage={"Imagem adicionada com sucesso!"}
          title={"Insira seu carimbo"}
        />
        </div>
        <FooterMenu selectedButton={currentPath}/>
    </div>
  );
};

export default ConfigPage;

import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import axios from "axios";
import Animation from "../Animation/index";

const AuthCallback: React.FC = () => {
  const {
    isAuthenticated,
    isLoading,
    user,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();
  const navigate = useNavigate();

  const getUserByEmail = async () => {
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: "https://dev-yudz81r54mtavkzj.us.auth0.com/api/v2/", // URL da API Management
          scope: "read:users read:current_user read:user_idp_tokens",
        },
      });

      const userID = user?.sub;
      //console.log("userid", userID);

      if (userID) {
        const options = {
          method: "GET",
          url: `https://login.letsdoc.ai/api/v2/users/${userID}`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        };

        const response = await axios.request(options);

        const loginsCount = response.data.logins_count;

        if (loginsCount > 1) {
          navigate("/novo-pedido");
        } else {
          navigate("/get-metadatas");
        }
      } else {
        console.error("User ID is undefined.");
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(
          "Error fetching user data:",
          error.response?.data || error.message
        );
      } else {
        console.error("An unexpected error occurred:", error);
      }
    }
  };

  const initiateLogin = async () => {
    try {
      await loginWithRedirect({
        authorizationParams: {
          audience: "https://dev-yudz81r54mtavkzj.us.auth0.com/api/v2/",
          scope: "read:current_user",
        },
      });
    } catch (err) {
      console.error("Erro ao iniciar o login:", err);
      throw new Error("Falha ao iniciar o login. Tente novamente.");
    }
  };

  // useEffect para garantir que ações ocorram após a autenticação
  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      // Autenticação realizada com sucesso

      // Irá redirecionar para tela de realizar pedido de cirurgia caso não for o primeiro acesso ou
      // Irá redirecionar para obter metadados como CRM e Nome completo caso for o primeiro login

      getUserByEmail(); // Chama a função após autenticação
    } else if (!isLoading && !isAuthenticated) {
      // Autenticação NÂO realizada com sucesso

      // Irá redirecionar para o login caso o usuário não estiver autenticado

      initiateLogin();
    }
  }, [isAuthenticated, isLoading, initiateLogin, getUserByEmail]); // Correção no array de dependências

  // Sempre que a variável do auth0 "isLoading" for false, irá renderizar a animação de carregamento
  return (
    <Animation />
  );
};

export default AuthCallback;

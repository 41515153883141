import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";
import "./styles.css";

interface ProtectedRouteProps {
  children: React.ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth0();

  //console.log("isAuthenticated:", isAuthenticated);
  if (isLoading) {
    //console.log(isAuthenticated);
    return (
      <div className="loading-container">
        <div className="image-wrapper">
          <img
            id="loading"
            src={`${process.env.PUBLIC_URL}/loading.svg`}
            alt="loading"
          />
          <img
            id="robot"
            src={`${process.env.PUBLIC_URL}/robot.svg`}
            alt="robot"
          />
        </div>
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default ProtectedRoute;

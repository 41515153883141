import React, { useEffect } from "react";
import Lottie from "lottie-react";
import Loading from "./loading.json";

const LottieAnimation = () => {
  
  return (
    <div style={{display: "flex", alignItems: "center", justifyContent: "center", height: "100vh"}}>
      <Lottie
        animationData={Loading}
        loop={true}
        autoplay={true}
        style={{ height: 176, width: 176, aspectRatio: "xMidYMid slice" }} // Ajuste do tamanho diretamente no style
      />
    </div>
  );
}

export default LottieAnimation;

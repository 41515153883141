import { createBrowserRouter, Navigate } from "react-router-dom";
import NewOrderPage from "../Pages/NewOrderPage/App";
import SupportPage from "../Pages/SupportPage/App";
import OrdersPage from "../Pages/OrdersPage/App";
import PlanPage from "../Pages/PlanPage/App";
import LangChainPage from "../Pages/LangChainPage/App";
import ExportarPedido from "../Pages/ExportarPedido/App";
import CheckBoxPage from "../Pages/CheckBoxPage/App";
import DownloadPDFPage from "../Pages/DownloadPDFPage/App";
import ProtectedRoute from "../components/ProtectedRoute/ProtectedRoute";
import AuthCallback from "../components/CallBackRoute/CallBackAuth";
import OPMEPage from "../Pages/OPMEPage/App";
import GetMetadas from "../Pages/GetMetadatas/App";
import { AppProvider } from "../context/AppContext";
import ConfigPage from "../Pages/ConfigPage/App";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/auth-callback" replace />,
  },
  // {
  //   path: '/prelogin',
  //   element: <PreLogin />,
  // },
  {
    path: "/auth-callback",
    element: <AuthCallback />,
  },
  {
    path: "/get-metadatas",
    element: <GetMetadas />,
  },
  {
    path: "/novo-pedido",
    element: (
      <ProtectedRoute>
        <AppProvider>
          <NewOrderPage />
        </AppProvider>
      </ProtectedRoute>
    ),
  },
  {
    path: "/pedidos-salvos",
    element: (
      <ProtectedRoute>
        <OrdersPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/suporte",
    element: (
      <ProtectedRoute>
        <SupportPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/langchain",
    element: (
      <ProtectedRoute>
        <LangChainPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/exportarPedido",
    element: (
      <ProtectedRoute>
        <ExportarPedido />
      </ProtectedRoute>
    ),
  },
  {
    path: "/planos",
    element: (
      <ProtectedRoute>
        <PlanPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/checkbox",
    element: (
      <ProtectedRoute>
        <AppProvider>
          <CheckBoxPage />
        </AppProvider>
      </ProtectedRoute>
    ),
  },
  {
    path: "/downloadpdf",
    element: (
      <ProtectedRoute>
        <AppProvider>
          <DownloadPDFPage />
        </AppProvider>
      </ProtectedRoute>
    ),
  },
  {
    path: "/opme",
    element: (
      <ProtectedRoute>
        <OPMEPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/config",
    element: (
      <ProtectedRoute>
        <ConfigPage/>
      </ProtectedRoute>
    ),
  },
]);

import { useState } from 'react';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.css';

const PlanPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [nomeCompleto, setNomeCompleto] = useState('');
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [numeroCartao, setNumeroCartao] = useState('');
  const [validadeCartao, setValidadeCartao] = useState('');
  const [cvv, setCvv] = useState('');
  const [focusedField, setFocusedField] = useState<string | null>(null);

  const handleFocus = (field: string) => {
    setFocusedField(field);
  };

  const handleBlur = (field: string, value: string) => {
    // Remove focus only if the field is empty
    if (value.trim() === '') {
      setFocusedField(null);
    }
  };

  const getResponse = async (input: string) => {};

  return (
    <main className="main-container">
      <div className="arrow">
        <img src="/arrow.svg" alt="Arrow Icon" />
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Slide}
      />
      <div className="greeting-container">
        <h2 className="greeting-title">Escolha seu plano</h2>
      </div>
      <div className="form-container">
        <div className="input-group">
          <div
            className={`input-wrapper ${
              focusedField === 'nomeCompleto' || nomeCompleto ? 'focused' : ''
            }`}
          >
            <label className="input-title">Nome Completo</label>
            <input
              disabled={loading}
              className="input-field"
              onChange={(e) => setNomeCompleto(e.target.value)}
              value={nomeCompleto}
              onFocus={() => handleFocus('nomeCompleto')}
              onBlur={() => handleBlur('nomeCompleto', nomeCompleto)}
            />
            <div className="underline"></div>
          </div>
        </div>
        <div className="input-group">
          <div
            className={`input-wrapper ${
              focusedField === 'cpfCnpj' || cpfCnpj ? 'focused' : ''
            }`}
          >
            <label className="input-title">CPF/ CNPJ</label>
            <input
              type="text"
              disabled={loading}
              className="input-field"
              onChange={(e) => setCpfCnpj(e.target.value)}
              value={cpfCnpj}
              onFocus={() => handleFocus('cpfCnpj')}
              onBlur={() => handleBlur('cpfCnpj', cpfCnpj)}
            />
            <div className="underline"></div>
          </div>
        </div>
        <div className="input-group">
          <div
            className={`input-wrapper ${
              focusedField === 'numeroCartao' || numeroCartao ? 'focused' : ''
            }`}
          >
            <label className="input-title">Número do Cartão</label>
            <input
              type="number"
              disabled={loading}
              className="input-field"
              onChange={(e) => setNumeroCartao(e.target.value)}
              value={numeroCartao}
              onFocus={() => handleFocus('numeroCartao')}
              onBlur={() => handleBlur('numeroCartao', numeroCartao)}
            />
            <div className="underline"></div>
          </div>
        </div>
        <div className="input-group">
          <div
            className={`input-wrapper ${
              focusedField === 'validadeCartao' || validadeCartao
                ? 'focused'
                : ''
            }`}
          >
            <label className="input-title">Validade (MM/AAAA)</label>
            <input
              type="text"
              disabled={loading}
              className="input-field"
              onChange={(e) => setValidadeCartao(e.target.value)}
              value={validadeCartao}
              onFocus={() => handleFocus('validadeCartao')}
              onBlur={() => handleBlur('validadeCartao', validadeCartao)}
            />
            <div className="underline"></div>
          </div>
        </div>
        <div className="input-group">
          <div
            className={`input-wrapper ${
              focusedField === 'cvv' || cvv ? 'focused' : ''
            }`}
          >
            <label className="input-title">Código (CVV)</label>
            <input
              type="number"
              disabled={loading}
              className="input-field"
              onChange={(e) => setCvv(e.target.value)}
              value={cvv}
              onFocus={() => handleFocus('cvv')}
              onBlur={() => handleBlur('cvv', cvv)}
            />
            <div className="underline"></div>
          </div>
        </div>
      </div>

      <div className="analisar-button-container">
        <button
          className={`analisar-button ${
            nomeCompleto && cpfCnpj && numeroCartao && validadeCartao && cvv
              ? 'enabled'
              : 'disabled'
          }`}
          onClick={() =>
            getResponse(`${nomeCompleto} ${cpfCnpj} ${numeroCartao}`)
          }
          disabled={
            !nomeCompleto ||
            !cpfCnpj ||
            !numeroCartao ||
            !validadeCartao ||
            !cvv
          }
        >
          Contratar
        </button>
      </div>
    </main>
  );
};

export default PlanPage;

import React, { createContext, useState, useContext, ReactNode } from "react";

// Tipagem dos valores no contexto
interface AppContextProps {
  items: string[][];
  setItems: React.Dispatch<React.SetStateAction<string[][]>>;
  quantities: number[];
  setQuantities: React.Dispatch<React.SetStateAction<number[]>>;
  checkboxItems: string[][];
  setCheckboxItems: React.Dispatch<React.SetStateAction<string[][]>>;
  informacoes: {
    nomePaciente: string;
    descricaoPaciente: string;
    procedimentos: string;
    cids: string;
    dataEmissao: string;
  };
  setInformacoes: React.Dispatch<
    React.SetStateAction<{
      nomePaciente: string;
      descricaoPaciente: string;
      procedimentos: string;
      cids: string;
      dataEmissao: string;
    }>
  >;
  isStreamingComplete: boolean;
  setIsStreamingComplete: React.Dispatch<React.SetStateAction<boolean>>;
  isPreparingOrder: boolean;
  setIsPreparingOrder: React.Dispatch<React.SetStateAction<boolean>>;
  showBoxArea: boolean;
  setShowBoxArea: React.Dispatch<React.SetStateAction<boolean>>;
  responseRendered: boolean;
  setResponseRendered: React.Dispatch<React.SetStateAction<boolean>>;
  EditableResponse: string;
  setEditableResponse: React.Dispatch<React.SetStateAction<string>>;
  checkedItems: Array<{ code: string; description: string; quantity: string }>;
  setCheckedItems: React.Dispatch<
    React.SetStateAction<Array<{ code: string; description: string; quantity: string }>>
  >;
  initialCheckboxItems: string[][];
  setInitialCheckboxItems: React.Dispatch<React.SetStateAction<string[][]>>;
  laudoText: string;
  setLaudoText: React.Dispatch<React.SetStateAction<string>>;
  patientName: string;
  setPatientName: React.Dispatch<React.SetStateAction<string>>;
  newInputRequest: string;
  setnewInputRequest: React.Dispatch<React.SetStateAction<string>>;
}

// Criação do contexto com valor inicial vazio
const AppContext = createContext<AppContextProps | undefined>(undefined);

// Provedor do contexto
export const AppProvider = ({ children }: { children: ReactNode }) => {
  const [items, setItems] = useState<string[][]>([]);
  const [quantities, setQuantities] = useState<number[]>([]);
  const [checkboxItems, setCheckboxItems] = useState<string[][]>([]);
  const [informacoes, setInformacoes] = useState({
    nomePaciente: "",
    descricaoPaciente: "",
    procedimentos: "",
    cids: "",
    dataEmissao: "",
  });
  const [isStreamingComplete, setIsStreamingComplete] = useState(false);
  const [isPreparingOrder, setIsPreparingOrder] = useState(false);
  const [showBoxArea, setShowBoxArea] = useState(false);
  const [responseRendered, setResponseRendered] = useState(false);
  const [EditableResponse, setEditableResponse] = useState<string>("");
  const [checkedItems, setCheckedItems] = useState<
    Array<{ code: string; description: string; quantity: string }>
  >([]);
  const [laudoText, setLaudoText] = useState("");
  const [patientName, setPatientName] = useState("");
  const [newInputRequest, setnewInputRequest] = useState("");
  const [initialCheckboxItems, setInitialCheckboxItems] = useState<string[][]>(
    []
  );

  return (
    <AppContext.Provider
      value={{
        items,
        setItems,
        quantities,
        setQuantities,
        checkboxItems,
        setCheckboxItems,
        informacoes,
        setInformacoes,
        isStreamingComplete,
        setIsStreamingComplete,
        isPreparingOrder,
        setIsPreparingOrder,
        showBoxArea,
        setShowBoxArea,
        responseRendered,
        setResponseRendered,
        EditableResponse,
        setEditableResponse,
        checkedItems,
        setCheckedItems,
        laudoText,
        setLaudoText,
        patientName,
        setPatientName,
        newInputRequest,
        setnewInputRequest,
        initialCheckboxItems,
        setInitialCheckboxItems
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

// Hook para facilitar o uso do contexto
export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext deve ser usado dentro de um AppProvider");
  }
  return context;
};

import React, { useState, useEffect } from "react";
import "./styles.css";
import { CiTrash } from "react-icons/ci";
import { FiPlus, FiMinus } from "react-icons/fi";
import { procedures_port } from "./procedures_ports";
import { useAppContext } from "../../context/AppContext";

interface Procedure {
  procedure_id: string;
  procedure_port: string;
}

interface CheckboxProps {
  texts: string[][];
  onSendCheckedItems: (
    checkedItems: Array<{
      code: string;
      description: string;
      quantity: string;
    }>,
    nome_paciente: string,
    laudo: string,
    historico: string
  ) => void;
  nome_paciente: string;
  laudo: string;
  historico: string;
  onItemsChange: (items: string[][]) => void;
}

const CheckBox: React.FC<CheckboxProps> = ({
  texts,
  onSendCheckedItems,
  nome_paciente,
  laudo,
  historico,
  onItemsChange,
}) => {
  const { items, setItems, quantities, setQuantities } = useAppContext();
  //const [items, setItems] = useState<string[][]>([]);
  //const [quantities, setQuantities] = useState<number[]>([]);
  const parseQuantity = (qty: string): number => {
    const quantityNumber = parseInt(qty.replace("x", ""));
    return isNaN(quantityNumber) ? 1 : quantityNumber;
  };

  useEffect(() => {
    setItems(texts);
    const initialQuantities = texts.map((item) => parseQuantity(item[2]));
    setQuantities(initialQuantities);
  }, [texts]);

  const extractPort = (port: string) => {
    const match = port.match(/(\d+)([A-Z])/);
    if (match) {
      return { number: parseInt(match[1]), letter: match[2] };
    }
    return { number: 0, letter: "" };
  };

  const comparePorts = (a: string, b: string) => {
    const portA = extractPort(a);

    const portB = extractPort(b);

    if (portA.number !== portB.number) {
      return portB.number - portA.number;
    }
    return portB.letter.localeCompare(portA.letter);
  };

  useEffect(() => {
    // Sort the items based on procedure_port
    const sortedItems = [...items].sort((a, b) => {
      const procedureA = procedures_port.find(
        (proc) => proc.procedure_id === a[0]
      );
      const procedureB = procedures_port.find(
        (proc) => proc.procedure_id === b[0]
      );

      if (procedureA && procedureB) {
        return comparePorts(
          procedureA.procedure_port,
          procedureB.procedure_port
        );
      }
      return 0;
    });

    if (JSON.stringify(sortedItems) !== JSON.stringify(items)) {
      setItems(sortedItems);
      onItemsChange(sortedItems);
    }
  }, [items, procedures_port, onItemsChange]);

  const handleQuantityChange = (index: number, increment: boolean) => {
    const newQuantities = [...quantities];
    const newQuantity = increment
      ? newQuantities[index] + 1
      : newQuantities[index] - 1;

    if (newQuantity >= 1) {
      newQuantities[index] = newQuantity;
      setQuantities(newQuantities);

      const newItems = [...items];
      newItems[index] = [
        newItems[index][0],
        newItems[index][1],
        `x${newQuantities[index]}`,
      ];
      setItems(newItems);
      onItemsChange(newItems);
    }
  };

  const handleRemoveItem = (itemToRemove: string[]) => {
    const newItems = items.filter((item) => item !== itemToRemove);
    setItems(newItems);
    onItemsChange(newItems);
  };

  const handleSendCheckedItems = () => {
    const checkedDictArray = items.map((item, index) => ({
      code: item[0],
      description: item[1],
      quantity: `x${quantities[index]}`,
    }));

    onSendCheckedItems(checkedDictArray, nome_paciente, laudo, historico);
  };

  return (
    <div className="checkbox-component-button">
      <div className="checkbox-component-div">
        {items.map((item, index) => (
          <div key={index} className="checkbox-component-item">
            <label>
              {item[0]} - {item[1]}
            </label>
            <div className="lateralidade-class">
              <div className="quantity-controls">
                <button
                  className="quantity-button"
                  onClick={() =>
                    quantities[index] === 1
                      ? handleRemoveItem(item)
                      : handleQuantityChange(index, false)
                  }
                >
                  {quantities[index] === 1 ? <CiTrash /> : <FiMinus />}
                </button>
                <button
                  className="quantity-button"
                  onClick={() => handleQuantityChange(index, true)}
                >
                  <FiPlus />
                </button>
                <span className="quantity-display">{quantities[index]}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
      <button className="enviar-button" onClick={handleSendCheckedItems}>
        Confirmar
      </button>
    </div>
  );
};

export default CheckBox;

import React from "react";
import "./styles.css";
import { useNavigate } from "react-router-dom";

interface FooterMenuProps {
  selectedButton: string | null;
}

const FooterMenu: React.FC<FooterMenuProps> = ({ selectedButton }) => {
  const navigate = useNavigate();

  return (
    <div className="footer-container">
      <button
        className={`footer-item ${
          selectedButton === "/novo-pedido" ? "selected" : "deselected"
        }`}
        onClick={() => navigate("/novo-pedido")}
      >
        <img src="/plus-icon.svg" alt="Novo Pedido" className="footer-icon" />
        <p>Novo Pedido</p>
      </button>
      <button
        className={`footer-item ${
          selectedButton === "/pedidos-salvos" ? "selected" : "deselected"
        }`}
        onClick={() => navigate("/pedidos-salvos")}
      >
        <img
          src="/saved-icon.svg"
          alt="Pedidos Salvos"
          className="footer-icon"
        />
        <p>Pedidos Salvos</p>
      </button>
      <button
        className={`footer-item ${
          selectedButton === "/suporte" ? "selected" : "deselected"
        }`}
        onClick={() =>
          window.open(
            "https://wa.me/5521988333106?text=Oi,%20preciso%20de%20ajuda%20com%20LetsDoc.",
            "_blank"
          )
        }
      >
        <img src="/support-icon.svg" alt="Suporte" className="footer-icon" />
        <p>Suporte</p>
      </button>
      <button
        className={`footer-item ${
          selectedButton === "/config" ? "selected" : "deselected"
        }`}
        onClick={() => navigate("/config")}
      >
        <img
          src="/account.png"
          alt="Configurações"
          className="footer-icon"
        />
        <p>Configurações</p>
      </button>
    </div>
  );
};

export default FooterMenu;

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./styles.css";
import { IoIosArrowDown } from "react-icons/io";
import { useAuth0 } from "@auth0/auth0-react";

interface InputResponse {
  query: string;
  response: string;
}

const DownloadPDFPage: React.FC = () => {
  const location = useLocation();
  const { checkedItems, patientName, laudo, historico, checkboxInitial } =
    location.state || {};
  const navigate = useNavigate();
  const ROTA = "https://api.letsdoc.ai";
  const [EditableResponse, setEditableResponse] = useState<string>("");
  const [inputResponse, setInputResponse] = useState<Array<InputResponse>>([]);
  const [showBoxArea, setShowBoxArea] = useState(false);
  const [showApprove, setShowApprove] = useState(false);
  const [responseRendered, setResponseRendered] = useState(false);
  const [isStreamingComplete, setIsStreamingComplete] = useState(false);
  const [isPreparingOrder, setIsPreparingOrder] = useState(false);
  const [showPlanSelection, setShowPlanSelection] = useState(false);
  const { user } = useAuth0();
  const EMAIL = user?.email;

  const [selectedPlan, setSelectedPlan] = useState<string>("Selecione o plano");
  const [showOptions, setShowOptions] = useState<boolean>(false);

  const [isDownloading, setIsDownloading] = useState(false);

  const [informacoes, setInformacoes] = useState({
    nomePaciente: "",
    descricaoPaciente: "",
    procedimentos: "",
    cids: "",
    dataEmissao: "",
    hospital: "",
  });

  const [informacoesIniciais, setInformacoesIniciais] = useState({
    ...informacoes,
  });
  const [informacoesAtualizadas, setInformacoesAtualizadas] = useState({});

  const [timestamp, setTimestamp] = useState("");

  let justOneCalled = true;

  const getToken = () => {
    const token = localStorage.getItem("authToken");
    return token;
  };

  const handlePlanSelection = (plan: string) => {
    setSelectedPlan(plan);
    setShowOptions(false);
  };

  const getResponseModel = async (
    checkedItems: Array<{
      code: string;
      description: string;
      quantity: string;
    }>,
    patientName: string,
    laudo: string,
    historico: string
  ) => {
    const token = getToken();
    const requestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        pedidos: checkedItems,
        nome_paciente: patientName,
        laudo: laudo,
        historico: historico,
      }),
    };

    try {
      const url = `${ROTA}/chat/output_checklist`;
      const response = await fetch(url, requestInit);

      if (response && response.body) {
        const reader = response.body
          .pipeThrough(new TextDecoderStream())
          .getReader();
        let responseText = "";
        let isFirstChunk = true;
        while (true) {
          const { value, done } = await reader.read();
          if (done) break;
          responseText += value;

          if (isFirstChunk) {
            isFirstChunk = false;
          }

          setInputResponse((prev) => {
            const newResponse = [...prev];
            if (
              newResponse.length > 0 &&
              newResponse[newResponse.length - 1].query ===
                "Esses são os procedimentos!"
            ) {
              newResponse[newResponse.length - 1].response = responseText;
            } else {
              newResponse.push({
                query: "Esses são os procedimentos!",
                response: responseText,
              });
            }
            return newResponse;
          });
          setEditableResponse(responseText);
        }

        const procedimentos_modelo: string = checkboxInitial
          .map((item: string[]) => item.join(" "))
          .join(", ");
        const procedimentos_validados: string = checkedItems
          .map(
            (item: any) => `${item.code} ${item.description} ${item.quantity}`
          )
          .join(", ");

        setTimeout(async () => {
          const requestInitLog = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              user_email: EMAIL,
              pacient_name: patientName,
              user_historico: historico,
              user_laudo: laudo,
              procedimentos_modelo: procedimentos_modelo,
              procedimentos_validados: procedimentos_validados,
              pedido_cirurgia: responseText,
              opme_list: [],
            }),
          };

          const url_log = `${ROTA}/chat/log`;

          const res = await fetch(url_log, requestInitLog);

          const result = await res.json();

          setTimestamp(result.response);
        }, 1000);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => {
        setResponseRendered(true);
      }, 800);
      setTimeout(() => {
        setIsStreamingComplete(true);
      }, 1000);
    }
  };

  const handleGoBackButton = () => {
    navigate("/checkbox");
  };

  const handleGoOpmePage = () => {
    navigate("/opme", {
      state: {
        checkedItems,
        EditableResponse,
        patientName,
        informacoesAtualizadas,
        timestamp,
      },
    });
  };

  useEffect(() => {
    if (justOneCalled && checkedItems && patientName && laudo && historico) {
      getResponseModel(checkedItems, patientName, laudo, historico);
      justOneCalled = false;
    }
  }, [checkedItems, patientName, laudo, historico]);

  const handleApproveOrder = () => {
    setShowApprove(true);
    setShowBoxArea(true);
    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    }, 250);
  };

  useEffect(() => {
    if (isStreamingComplete) {
      setIsPreparingOrder(true);
      const timer = setTimeout(() => {
        setIsPreparingOrder(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [isStreamingComplete]);

  function extrairInformacoes(texto: string) {
    const regexNomePaciente = /Nome do paciente:\s*(.*)/;
    const regexDescricaoPaciente =
      /(?<=Descrição do paciente:\n)([\s\S]*?)(?=\n-+)/;
    const regexProcedimentos = /(?<=Procedimentos:\n)([\s\S]*?)(?=\n-+)/;
    const regexCids = /(?<=CID's:\n)([\s\S]*?)(?=\n-+)/;
    const regexDataEmissao = /(?<=Data de emissão:\n)(.*)/;
    const regexNomeHospital = /Hospital:\s*(.*)/;

    const nomePaciente = texto.match(regexNomePaciente)?.[1]?.trim() || "";
    const descricaoPaciente =
      texto.match(regexDescricaoPaciente)?.[0]?.trim() || "";
    const procedimentos = texto.match(regexProcedimentos)?.[0]?.trim() || "";
    const cids = texto.match(regexCids)?.[0]?.trim() || "";
    const dataEmissao = texto.match(regexDataEmissao)?.[0]?.trim() || "";
    const hospital = texto.match(regexNomeHospital)?.[1]?.trim() || "";

    const procedimentosCorrigidos = formatarProcedimentos(procedimentos);

    return {
      nomePaciente,
      descricaoPaciente,
      procedimentos: procedimentosCorrigidos,
      cids,
      dataEmissao,
      hospital,
    };
  }

  function formatarProcedimentos(procedimentos: string): string {
    const linhasProcedimentos = procedimentos.split("\n");

    const procedimentosCorrigidos = linhasProcedimentos.map((linha) => {
      const match = linha.match(/^(\d+)\s*-?\s*x?(\d+)\s*-?\s*(.*)$/);

      if (match) {
        const codigo = match[1];
        const quantidade = match[2];
        const descricao = match[3];
        return `${codigo} x${quantidade} ${descricao}`;
      } else {
        return linha;
      }
    });

    return procedimentosCorrigidos.join("\n");
  }

  useEffect(() => {
    if (EditableResponse) {
      const dadosExtraidos = extrairInformacoes(EditableResponse);
      setInformacoes(dadosExtraidos);
    }
  }, [EditableResponse]);

  const calculateTextareaRows = (
    text: string,
    width: number,
    fontSize: number = 16
  ) => {
    const averageCharWidth = fontSize * 0.5;
    const charsPerLine = Math.floor(width / averageCharWidth);

    const lines = text.split("\n");
    let totalRows = lines.length;

    for (const line of lines) {
      totalRows += Math.ceil(line.length / charsPerLine) - 1;
    }

    return Math.max(totalRows, 1);
  };

  const handleDownloadPDF = async () => {
    setIsDownloading(true);
    const token = getToken();
    const template = selectedPlan;

    const pedido_cirurgia = `
    Aqui está seu pedido de cirurgia:
    -------------------------------------------------
    Nome do paciente:
    ${informacoes.nomePaciente}
    -------------------------------------------------
    Descrição do paciente:
    ${informacoes.descricaoPaciente}
    -------------------------------------------------
    Procedimentos:
    ${informacoes.procedimentos}
    -------------------------------------------------
    CID's:
    ${informacoes.cids}
    -------------------------------------------------
    Data de emissão:
    ${informacoes.dataEmissao}
    -------------------------------------------------
    Hospital:
    ${informacoes.hospital}
    -------------------------------------------------`;

    const requestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        email: EMAIL,
        pedido_de_cirurgia: pedido_cirurgia,
        template_type: template,
      }),
    };

    try {
      const url = `${ROTA}/chat/exportPDF`;

      const response = await fetch(url, requestInit);

      const blob = await response.blob();
      const urlBlob = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = urlBlob;
      const name_link = informacoes.nomePaciente.replace(/\s/g, "");
      link.download = `${name_link}_pedido_de_cirurgia.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    } finally {
      setIsDownloading(false);
    }
  };

  const handleDownloadResumo = async () => {
    setIsDownloading(true);
    const token = getToken();

    const pedido_cirurgia = `
    Aqui está seu pedido de cirurgia:
    -------------------------------------------------
    Nome do paciente:
    ${informacoes.nomePaciente}
    -------------------------------------------------
    Descrição do paciente:
    ${informacoes.descricaoPaciente}
    -------------------------------------------------
    Procedimentos:
    ${informacoes.procedimentos}
    -------------------------------------------------
    CID's:
    ${informacoes.cids}
    -------------------------------------------------
    Data de emissão:
    ${informacoes.dataEmissao}
    -------------------------------------------------
    Hospital:
    ${informacoes.hospital}
    -------------------------------------------------`;

    const requestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        email: EMAIL,
        pedido_de_cirurgia: pedido_cirurgia,
        materiais_opme: [],
      }),
    };

    try {
      const url = `${ROTA}/chat/resumo`;

      const response = await fetch(url, requestInit);

      const blob = await response.blob();
      const urlBlob = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = urlBlob;
      const name_link = informacoes.nomePaciente.replace(/\s/g, "");
      link.download = `${name_link}_resumo.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    } finally {
      setIsDownloading(false);
    }
  };

  const handleFieldChange = (field: string, value: string) => {
    setInformacoes((prev) => ({ ...prev, [field]: value }));

    // Verifique se o valor foi alterado em relação ao inicial
    if (
      value !== informacoesIniciais[field as keyof typeof informacoesIniciais]
    ) {
      setInformacoesAtualizadas((prev) => ({ ...prev, [field]: value }));
    } else {
      // Se o valor voltar ao original, remova-o das atualizações
      setInformacoesAtualizadas((prev) => {
        const updated = { ...prev };
        delete updated[field as keyof typeof updated];
        return updated;
      });
    }
  };

  useEffect(() => {
    // Configura os valores iniciais ao carregar o componente
    setInformacoesIniciais({ ...informacoes });
  }, []);

  return (
    <main className="download-page__main-container">
      <div className="download-page__header">
        <img
          id="arrow"
          src={`${process.env.PUBLIC_URL}/arrow.svg`}
          alt="arrow"
          style={{ width: "30px", height: "30px" }}
          onClick={handleGoBackButton}
        />

        <h1 className="download-page__title">Pedido gerado e salvo</h1>
      </div>
      <div>
        {!isStreamingComplete &&
          EditableResponse.split("\n").map((paragraph, paragraphIndex) => {
            if (paragraph.startsWith("###")) {
              return (
                <h3
                  key={paragraphIndex}
                  className="download-page__response-title"
                >
                  {paragraph.replace("###", "").trim()}
                </h3>
              );
            } else if (paragraph.startsWith("-")) {
              return (
                <li
                  key={paragraphIndex}
                  className="download-page__response-list-item"
                >
                  {paragraph}
                </li>
              );
            } else {
              const formattedParagraph = paragraph
                .split("**")
                .map((chunk, chunkIndex) => {
                  return chunkIndex % 2 === 0 ? (
                    <span key={chunkIndex}>{chunk}</span>
                  ) : (
                    <b key={chunkIndex}>{chunk}</b>
                  );
                });

              return (
                <p
                  key={paragraphIndex}
                  className="download-page__response-paragraph"
                >
                  {formattedParagraph}
                </p>
              );
            }
          })}

        {isPreparingOrder && (
          <div className="loading-message">
            <p>Estamos preparando seu pedido estruturado</p>
            <div className="loading-circle"></div>
          </div>
        )}
      </div>

      {!isPreparingOrder && isStreamingComplete && (
        <div className="editable-boxes">
          <label htmlFor="nomePaciente">Nome do Paciente:</label>
          <div className="editable-box">
            <textarea
              rows={calculateTextareaRows(informacoes.nomePaciente, 327)}
              id="nomePaciente"
              value={informacoes.nomePaciente}
              onChange={(e) =>
                handleFieldChange("nomePaciente", e.target.value)
              }
            />
          </div>
          <label htmlFor="descricaoPaciente">Descrição do Paciente:</label>
          <div className="editable-box">
            <textarea
              rows={
                calculateTextareaRows(informacoes.descricaoPaciente, 327) + 2
              }
              id="descricaoPaciente"
              value={informacoes.descricaoPaciente}
              onChange={(e) =>
                handleFieldChange("descricaoPaciente", e.target.value)
              }
            />
          </div>
          <label htmlFor="procedimentosPaciente">Procedimentos:</label>
          <div className="editable-box">
            <textarea
              className="procedures-textarea"
              rows={calculateTextareaRows(informacoes.procedimentos, 327)}
              id="procedimentos"
              value={informacoes.procedimentos}
              onChange={(e) =>
                handleFieldChange("procedimentos", e.target.value)
              }
              readOnly
              style={{
                pointerEvents: "none",
                backgroundColor: "#f0f0f0",
                color: "#555",
              }}
            />
          </div>
          <label htmlFor="cids">CID's:</label>
          <div className="editable-box">
            <textarea
              rows={calculateTextareaRows(informacoes.cids, 327)}
              id="cids"
              value={informacoes.cids}
              onChange={(e) => handleFieldChange("cids", e.target.value)}
            />
          </div>
          <label htmlFor="dataEmissao">Data de Emissão:</label>
          <div className="editable-box">
            <textarea
              rows={calculateTextareaRows(informacoes.dataEmissao, 327)}
              id="dataEmissao"
              value={informacoes.dataEmissao}
              onChange={(e) => handleFieldChange("dataEmissao", e.target.value)}
            />
          </div>
          <label htmlFor="dataEmissao">Hospital:</label>
          <div className="editable-box">
            <textarea
              rows={calculateTextareaRows(informacoes.hospital, 327)}
              id="hospitalName"
              value={informacoes.hospital}
              onChange={(e) => handleFieldChange("hospital", e.target.value)}
            />
          </div>
        </div>
      )}
      {!showApprove && !isPreparingOrder && isStreamingComplete && (
        <div className="download-page__approve-box">
          <button className="approve-button" onClick={handleApproveOrder}>
            Aprovar Pedido
          </button>
        </div>
      )}

      {showBoxArea && (
        <div className="download-page__actions">
          <div className="download-page__surgery-button">
            <button
              className="download-page__download-button"
              onClick={() => setShowPlanSelection(true)}
            >
              Guia de internação
            </button>
          </div>
          {showPlanSelection && (
            <div className="modal-overlay">
              <div className="modal">
                <button
                  className="modal-close-button"
                  onClick={() => setShowPlanSelection(false)}
                >
                  &times;
                </button>
                <h1>Escolha o plano de saúde</h1>
                <div
                  className="download-page__selected"
                  onClick={() => setShowOptions(!showOptions)}
                >
                  {selectedPlan}
                  <IoIosArrowDown color="#4B73FF" />
                </div>
                <div
                  className={`download-page__options-container ${
                    showOptions ? "active" : ""
                  }`}
                >
                  {[
                    "AMIL",
                    "BRADESCO",
                    "CAC",
                    "CASSI",
                    "EMBRATEL",
                    "EVERCROSS",
                    "GAMA",
                    "GEAP",
                    "GOLDEN_CROSS",
                    "INTERMEDICA",
                    "LEVESAUDE",
                    "MUTUA",
                    "OMINT",
                    "PASAVALE",
                    "PETROBRAS",
                    "SULAMERICA",
                    "UNIMED",
                  ].map((plan) => (
                    <div
                      key={plan}
                      className="download-page__option"
                      onClick={() => handlePlanSelection(plan)}
                    >
                      <input
                        type="radio"
                        className="download-page__radio"
                        id={plan}
                        name="category"
                        checked={selectedPlan === plan}
                        readOnly
                      />
                      <label htmlFor={plan}>{plan}</label>
                    </div>
                  ))}
                </div>
                <button
                  className="download-page__download-button"
                  disabled={selectedPlan === "Selecione o plano"}
                  onClick={handleDownloadPDF}
                >
                  {isDownloading ? (
                    <div className="loading-circle"></div>
                  ) : (
                    "Download"
                  )}
                </button>
              </div>
            </div>
          )}
          <button className="resumo-button" onClick={handleDownloadResumo}>
            {isDownloading ? (
              <div className="loading-circle-resumo"></div>
            ) : (
              "Resumo"
            )}
          </button>
          <button className="opme-button" onClick={handleGoOpmePage}>
            OPME
          </button>
        </div>
      )}
    </main>
  );
};

export default DownloadPDFPage;

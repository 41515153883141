import "./styles.css";

const Loading: React.FC = () => {
  return (
    <div className="loading-container">
      <div className="image-wrapper">
        <img
          id="loading"
          src={`${process.env.PUBLIC_URL}/loading.svg`}
          alt="loading"
        />
        <img
          id="robot"
          src={`${process.env.PUBLIC_URL}/robot.svg`}
          alt="robot"
        />
      </div>
    </div>
  );
};

export default Loading;

import React, { useState, useRef, useEffect, ChangeEvent } from 'react';
import "./styles.css";
import { CiSearch } from "react-icons/ci";
import { procedures } from './proceduresData';
import { ReactComponent as SearchIcon } from "../../../public/search.svg";



interface SearchBarProps {
  onSearch: (filteredNames: string[][]) => void;
  onCheckboxItemsUpdate: (item: string[]) => void;
 
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearch, onCheckboxItemsUpdate }) => {
  const [query, setQuery] = useState<string>('');
  const [filteredNames, setFilteredNames] = useState<[string, string][]>([]);
  const searchBarRef = useRef<HTMLDivElement>(null);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setQuery(inputValue);

    const proceduresArray = Object.entries(procedures);
    const filteredNames = proceduresArray
      .filter(([code, name]) =>
        code.toLowerCase().includes(inputValue.toLowerCase()) ||
        name.toLowerCase().includes(inputValue.toLowerCase())
      );

    setFilteredNames(filteredNames);
  };

  const handleSearch = () => {
    filteredNames.forEach(([code, name]) => {
      onCheckboxItemsUpdate([code, name, 'x1']); 
    });
  };


  const handleItemClick = ([code, name]: [string, string]) => {
    
    onCheckboxItemsUpdate([code, name, "x1"]);
    setFilteredNames([]);
    setQuery('');
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (searchBarRef.current && !searchBarRef.current.contains(event.target as Node)) {
      setQuery('');
      setFilteredNames([]);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={searchBarRef} className='searchBarContainer'>
      <div className='searchBar-search'>
      <div onClick={handleSearch} className='search-icon'>
        <img id="search" src={`${process.env.PUBLIC_URL}/search.svg`} alt="search" />
        </div>
        <input
          type="text"
          value={query}
          onChange={handleInputChange}
          placeholder="Busque por um código ou procedimento"
          className='search-input'
        />
      </div>
      <ul className='search-results'>
        {filteredNames.map(([code, name], index) => (
          <li key={index} onClick={() => handleItemClick([code, name])}>
            {code} -- {name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SearchBar;
import { useEffect, useRef, useState } from "react";
import { FaLocationArrow, FaRobot, FaSync, FaUserCircle } from "react-icons/fa";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import CheckBox from "../../components/Checkbox";
import SearchBar from "../../components/SearchBar";
import { procedures } from "../../components/SearchBar/proceduresData";
import "./styles.css";
import { IoSend } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { IoDocumentAttachOutline } from "react-icons/io5";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FooterMenu from "../../components/FooterMenu";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useToken } from "../../services/api";
import { useAppContext } from "../../context/AppContext";

interface InputResponse {
  query: string;
  response: string;
}

interface CheckBoxProps {
  CheckBoxItems: string[][];
}

const NewOrderPage: React.FC = () => {
  const ROTA = process.env.REACT_APP_API_ENDPOINT;
  const ROTA_PRODUCAO = "https://api.letsdoc.ai";
  //console.log(ROTA);
  const [loading, setLoading] = useState(false);
  //const [newInputRequest, setnewInputRequest] = useState("");
  const [apiInput, setApiInput] = useState("");
  const [inputResponse, setInputResponse] = useState<Array<InputResponse>>([]);
  const [responseComplete, setResponseComplete] = useState(false);
  const [SecondresponseComplete, setSecondResponseComplete] = useState(false);
  //const [checkboxItems, setCheckboxItems] = useState<string[][]>([]);
  const [checkedItems, setCheckedItems] = useState<
    Array<{ code: string; description: string; quantity: string }>
  >([]);
  const [searchResults, setSearchResults] = useState<string[][]>([]);
  const newInputRequestRef = useRef<HTMLTextAreaElement>(null);
  const laudoTextRef = useRef<HTMLTextAreaElement>(null);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [showInitialPrompt, setShowInitialPrompt] = useState(true);
  //const [laudoText, setLaudoText] = useState("");
  const [talkingText, setTalkingText] = useState("");
  const [showTextArea, setShowTextArea] = useState(true);
  const [showGreetings, setShowGreetings] = useState(true);
  const [showChatBox, setshowChatBox] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  // const [initialCheckboxItems, setInitialCheckboxItems] = useState<string[][]>(
  //   []
  // );
  const [file, setFile] = useState<File | null>(null);
  const [filePreview, setFilePreview] = useState<string | null>(null);
  //const [patientName, setPatientName] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editableResponseText, setEditableResponseText] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  const [isFocusedRequest, setIsFocusedRequest] = useState(false);
  const [isFocusedLaudo, setIsFocusedLaudo] = useState(false);
  const [abortController, setAbortController] =
    useState<AbortController | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const { getToken } = useToken();

  const {
    patientName,
    setPatientName,
    laudoText,
    setLaudoText,
    newInputRequest,
    setnewInputRequest,
    checkboxItems,
    setCheckboxItems,
    initialCheckboxItems,
    setInitialCheckboxItems,
  } = useAppContext();

  useEffect(() => {
    const fetchToken = async () => {
      const fetchedToken = await getToken();
      setToken(fetchedToken);
    };
    fetchToken();
  }, [getToken]);

  const { logout } = useAuth0();

  const extractData = (text: string): string[][] => {
    const regex_description = /(\d{8})/g;
    const matches_description = text.match(regex_description);

    const regex_quantity = /x\d+/g;
    const matches_quantity = text.match(regex_quantity);
    const quantity = matches_quantity || [];

    const matrix: string[][] = [];

    if (matches_description) {
      for (let i = 0; i < matches_description.length; i++) {
        const code = matches_description[i];
        if (procedures[code as keyof typeof procedures]) {
          const desc = procedures[code as keyof typeof procedures];
          const qty = quantity[i];
          if (desc && qty) {
            matrix.push([code, desc, qty]);
          }
        }
      }
    }

    return matrix;
  };

  const extractFinalData = (text: string): string => {
    const regex_description = /(\d{8})/g;
    const matches_description = text.match(regex_description);

    const regex_quantity = /x\d+/g;
    const matches_quantity = text.match(regex_quantity);
    const quantity = matches_quantity || "";

    let result = "";

    if (matches_description) {
      for (let i = 0; i < matches_description.length; i++) {
        const code = matches_description[i];
        if (procedures[code as keyof typeof procedures]) {
          const desc = procedures[code as keyof typeof procedures];
          const qty = quantity[i];
          if (desc && qty) {
            result += `${code} ${desc} ${qty} `;
          }
        }
      }
    }

    return result.trim();
  };

  const getResponse = async (input: string) => {
    if (!token) {
      console.error("Token não disponível");
      return;
    }

    const combinedText = `Histórico do cliente:\n${newInputRequest}\n————————————————————————————————————\nlaudo:\n${laudoText}`;
    if (input.length === 0) return;

    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    try {
      setLoading(true);
      setResponseComplete(false);
      setShowInitialPrompt(false);
      setShowGreetings(false);

      const requestInit = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ input: combinedText }),
        signal: signal,
      };

      const url = `${ROTA_PRODUCAO}/chat/query_v1`;
      const response = await fetch(url, requestInit);

      if (response && response.body) {
        const reader = response.body
          .pipeThrough(new TextDecoderStream())
          .getReader();
        let responseText = "";
        while (true) {
          const { value, done } = await reader.read();
          if (done) break;
          responseText += value;
          setInputResponse((prev) => {
            const newResponse = [...prev];
            if (
              newResponse.length > 0 &&
              newResponse[newResponse.length - 1].query === input
            ) {
              newResponse[newResponse.length - 1].response = responseText;
            } else {
              newResponse.push({ query: input, response: responseText });
            }
            return newResponse;
          });
        }
        const initialItems = extractData(responseText);
        const name = extractName(responseText);
        setPatientName(name);
        setCheckboxItems(initialItems);
        setInitialCheckboxItems(initialItems);
        setShowCheckbox(true);
        setResponseComplete(true);
        setShowTextArea(false);
        setUserId(getUserId(responseText));
        if (!initialItems.length || !name || name === "n") {
          toast.error(
            `Nenhum${!initialItems.length ? " procedimento " : ""}${
              !initialItems.length && (!name || name === "n") ? "e" : ""
            }${!name || name === "n" ? " nome do paciente " : " "}encontrado${
              !initialItems.length && (!name || name === "n") ? "s" : ""
            }!`
          );
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        if (error.name === "AbortError") {
          console.log("Requisição abortada.");
        } else {
          console.error("Erro na requisição:", error.message);
        }
      } else {
        console.error("Erro desconhecido", error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      initialCheckboxItems.length &&
      (patientName && patientName !== "n") &&
      laudoText &&
      newInputRequest
    ) {
      navigate("/checkbox", {
        state: {
          patientName: patientName,
          checkboxInitial: initialCheckboxItems,
          laudoText,
          newInputRequest,
        },
      });
    }
  }, [initialCheckboxItems, patientName, laudoText, newInputRequest]);

  const delete_engine = async (userId: string) => {
    await fetch("https://api.letsdoc.ai/chat/delete-engine", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ session_id: userId }),
    });
  };

  function getUserId(response: string) {
    const firstLine = response.split("\n")[0];
    return firstLine;
  }

  const saveText = async (input?: string) => {
    try {
      const response = await fetch("https://api.letsdoc.ai/chat/save", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ input }),
      });
      if (response.ok) {
        console.log("Texto salvo com sucesso!");
      } else {
        console.log("Erro ao salvar o texto");
      }
    } catch (error) {
      console.log("Erro ao salvar o texto");
    }
  };

  const handleEnviar = () => {
    saveText(apiInput);
    setApiInput("");
  };

  useEffect(() => {
    const textarea = newInputRequestRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [newInputRequest]);

  useEffect(() => {
    const textarea = laudoTextRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [laudoText]);

  const handleCheckedItems = (
    checkedItems: Array<{
      code: string;
      description: string;
      quantity: string;
    }>,
    nome_paciente: string,
    laudo: string,
    historico: string
  ) => {
    setCheckedItems(checkedItems);
    sendCheckedItems(checkedItems, nome_paciente, laudo, historico);
    setInputResponse([]);
  };

  const handleSearch = (filteredNames: string[][]) => {
    setSearchResults(filteredNames);
  };

  const handleCheckboxItemsUpdate = (item: string[]) => {
    setCheckboxItems((prevItems) => {
      const existingItemIndex = prevItems.findIndex(
        (prevItem) => prevItem[0] === item[0]
      );
      if (existingItemIndex === -1) {
        const updatedItems = [...prevItems, item];
        handleSearch(updatedItems);

        return updatedItems;
      } else {
        if (item[2] === prevItems[existingItemIndex][2]) {
          toast.error("Esse procedimento já está no pedido.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Slide,
          });
        }
      }
      return prevItems;
    });
  };

  const sendCheckedItems = async (
    checkedItems: Array<{
      code: string;
      description: string;
      quantity: string;
    }>,
    nome_paciente: string,
    laudo: string,
    historico: string
  ) => {
    const requestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        pedidos: checkedItems,
        nome_paciente: nome_paciente,
        laudo: laudo,
        historico: historico,
      }),
    };

    try {
      setLoading(true);
      setResponseComplete(false);
      setShowInitialPrompt(true);
      const url = "https://api.letsdoc.ai/chat/output_checklist";
      const response = await fetch(url, requestInit);

      if (response && response.body) {
        const reader = response.body
          .pipeThrough(new TextDecoderStream())
          .getReader();
        let responseText = "";
        let isFirstChunk = true;
        while (true) {
          const { value, done } = await reader.read();
          if (done) break;
          responseText += value;

          if (isFirstChunk) {
            setLoading(false);
            isFirstChunk = false;
          }

          setInputResponse((prev) => {
            const newResponse = [...prev];
            if (
              newResponse.length > 0 &&
              newResponse[newResponse.length - 1].query ===
                "Esses são os procedimentos!"
            ) {
              newResponse[newResponse.length - 1].response = responseText;
            } else {
              newResponse.push({
                query: "Esses são os procedimentos!",
                response: responseText,
              });
            }

            return newResponse;
          });
        }
        setEditableResponseText(responseText);

        setShowCheckbox(false);
        setResponseComplete(true);
        setshowChatBox(true);
        setSecondResponseComplete(true);
        delete_engine(userId);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const convertCheckboxItemsToString = (items: string[][]): string => {
    return items.map((item) => item.join(" ")).join(" ");
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0] || null;
    setFile(selectedFile);
    if (selectedFile) {
      const fileURL = URL.createObjectURL(selectedFile);
      setFilePreview(fileURL);
    } else {
      setFilePreview(null);
    }
  };

  const extractName = (text: string): string => {
    const regex_patientName = /Nome do paciente:\s*\\n?([\wÀ-ÖØ-öø-ÿ\s'-]+)/i;
    const patientNameMatch = text.match(regex_patientName);
    const patientName = patientNameMatch ? patientNameMatch[1].trim() : "";
    //console.log("Match result:", patientNameMatch);
    //console.log("Extracted patient name:", patientName);
    return patientName;
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleResponseChange = (newText: string) => {
    setEditableResponseText(newText);
  };

  const handleCheckboxItemsAllTime: (items: string[][]) => void = (items) => {
    setCheckboxItems(items);
    //console.log(checkboxItems);
  };

  const handleLogClick = async () => {
    const procedimentos_modelo =
      convertCheckboxItemsToString(initialCheckboxItems);
    const user_laudo = `${laudoText}`;
    const user_historico = `${newInputRequest}`;
    const extractedData = extractFinalData(editableResponseText);
    const pedido_cirurgia = `${editableResponseText}`;
    setLoading(true);
    const requestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        user_historico: user_historico,
        user_laudo: user_laudo,
        procedimentos_modelo: procedimentos_modelo,
        procedimentos_validados: extractedData,
        pedido_cirurgia: pedido_cirurgia,
      }),
    };

    try {
      const url = "https://api.letsdoc.ai/chat/log";
      const response = await fetch(url, requestInit);

      if (response.ok) {
        await handleDownloadPDF(pedido_cirurgia);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadPDF = async (pedido_cirurgia: string) => {
    const requestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        pedido_de_cirurgia: pedido_cirurgia,
        template_type: "mediservice-bradesco",
      }),
    };

    try {
      const url = "https://api.letsdoc.ai/chat/exportPDF";
      const response = await fetch(url, requestInit);
      const blob = await response.blob();
      const urlBlob = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = urlBlob;
      link.download = "pedido_de_cirurgia.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = () => {
    if (abortController) {
      abortController.abort(); // Interromper a requisição
    }
    setLoading(false); // Para o estado de carregamento
    setInputResponse([]); // Reseta as respostas
    setShowTextArea(true); // Volta para a tela de entrada
    setResponseComplete(false); // Reseta o estado de resposta completa
    setShowCheckbox(false); // Oculta o checkbox
  };

  return (
    <main className="neworder-main-container">
      <button
        className="neworder-image-button"
        onClick={() =>
          logout({
            logoutParams: {
              returnTo: window.location.origin + "/auth-callback",
            },
          })
        }
      >
        <img
          src={`${process.env.PUBLIC_URL}/user-account.svg`}
          alt="Icon"
          className="button-icon"
        />
        Logout
      </button>

      <div className="neworder-greeting-container">
        <h2 className="neworder-greeting-title">Novo Pedido de Cirurgia</h2>
      </div>

      <div className="neworder-form-container">
        <div
          className={`neworder-input-group ${
            isFocusedRequest || newInputRequest ? "neworder-focused" : ""
          }`}
        >
          <label className="neworder-input-label">1</label>
          <div className="neworder-input-wrapper">
            <label className="neworder-input-title">
              Digite um breve histórico do paciente
            </label>
            <div className="neworder-textarea-wrapper">
              <textarea
                disabled={loading}
                className="neworder-input-field"
                onChange={(e) => setnewInputRequest(e.target.value)}
                value={newInputRequest}
                onFocus={() => setIsFocusedRequest(true)}
                onBlur={() => setIsFocusedRequest(false)}
                ref={newInputRequestRef}
                rows={1}
                style={{ lineHeight: "1.2" }}
              />
              <div className="neworder-underline"></div>
            </div>
          </div>
        </div>

        <div
          className={`neworder-input-group ${
            isFocusedLaudo || laudoText ? "neworder-focused" : ""
          }`}
        >
          <label className="neworder-input-label">2</label>
          <div className="neworder-input-wrapper">
            <label className="neworder-input-title">
              Insira resultados de exames complementares
            </label>
            <div className="neworder-textarea-wrapper">
              <textarea
                disabled={loading}
                className="neworder-input-field"
                onChange={(e) => setLaudoText(e.target.value)}
                value={laudoText}
                onFocus={() => setIsFocusedLaudo(true)}
                onBlur={() => setIsFocusedLaudo(false)}
                ref={laudoTextRef}
                rows={1}
                style={{ lineHeight: "1.2" }}
              />
              <div className="neworder-underline"></div>
            </div>
          </div>
        </div>

        <div className="neworder-analisar-button-container">
          {loading ? (
            <div className="neworder-centered-loading"></div>
          ) : (
            <button
              className={`neworder-analisar-button ${
                newInputRequest && laudoText ? "" : "disabled"
              }`}
              onClick={() => getResponse(`${newInputRequest} ${laudoText}`)}
              disabled={!newInputRequest || !laudoText}
            >
              Analisar
            </button>
          )}
        </div>
      </div>

      {loading && !responseComplete && (
        <div className="neworder-centered-loading">
          <div className="neworder-loading-content">
            <div className="neworder-loading-wrapper">
              <img
                src="/loading.svg"
                alt="Loading circle"
                className="neworder-loading-icon"
              />
              <img
                src="/robot.svg"
                alt="Loading robot"
                className="neworder-robot-icon"
              />
            </div>
            <p className="neworder-loading-text">
              Buscando códigos de cirurgia
            </p>
            <br></br>
            <button className="neworder-cancel-button" onClick={handleCancel}>
              Cancelar
            </button>
          </div>
        </div>
      )}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        className="custom-toast"
      />
      <div>
        <FooterMenu selectedButton={currentPath} />
      </div>
    </main>
  );
};

export default NewOrderPage;

import { useEffect, useRef, useState } from "react";
import { FaLocationArrow, FaRobot, FaSync, FaUserCircle } from "react-icons/fa";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import CheckBox from "../../components/Checkbox";
import SearchBar from "../../components/SearchBar";
import { procedures } from "../../components/SearchBar/proceduresData";
import "./styles.css";
import { IoSend } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { IoDocumentAttachOutline } from "react-icons/io5";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React from "react";

interface InputResponse {
  query: string;
  response: string;
}

interface CheckBoxProps {
  CheckBoxItems: string[][];
}

const LangChainPage: React.FC = () => {
  const ROTA_PRODUCAO = " https://api.letsdoc.ai";
  const ROTA_LOCALHOST = "http://localhost:8000";
  const [loading, setLoading] = useState(false);
  const [newInputRequest, setnewInputRequest] = useState("");
  const [apiInput, setApiInput] = useState("");
  const [inputResponse, setInputResponse] = useState<Array<InputResponse>>([]);
  const [responseComplete, setResponseComplete] = useState(false);
  const [SecondresponseComplete, setSecondResponseComplete] = useState(false);
  const [checkboxItems, setCheckboxItems] = useState<string[][]>([]);
  const [checkedItems, setCheckedItems] = useState<
    Array<{ code: string; description: string; quantity: string }>
  >([]);
  const [searchResults, setSearchResults] = useState<string[][]>([]);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [showInitialPrompt, setShowInitialPrompt] = useState(true);
  const [laudoText, setLaudoText] = useState("");
  const [talkingText, setTalkingText] = useState("");
  const [showTextArea, setShowTextArea] = useState(true);
  const [showGreetings, setShowGreetings] = useState(true);
  const [showChatBox, setshowChatBox] = useState(false);
  const navigate = useNavigate();
  const [initialCheckboxItems, setInitialCheckboxItems] = useState<string[][]>(
    []
  );
  const [file, setFile] = useState<File | null>(null);
  const [filePreview, setFilePreview] = useState<string | null>(null);
  const [patientName, setPatientName] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editableResponseText, setEditableResponseText] = useState<string>("");
  const [userId, setUserId] = useState<string>("");

  const extractData = (text: string): string[][] => {
    const regex_description = /(\d{8})/g;
    const matches_description = text.match(regex_description);

    const regex_quantity = /x\d+/g;
    const matches_quantity = text.match(regex_quantity);
    const quantity = matches_quantity || [];

    const matrix: string[][] = [];

    if (matches_description) {
      for (let i = 0; i < matches_description.length; i++) {
        const code = matches_description[i];
        if (procedures[code as keyof typeof procedures]) {
          const desc = procedures[code as keyof typeof procedures];
          const qty = quantity[i];
          if (desc && qty) {
            matrix.push([code, desc, qty]);
          }
        }
      }
    }

    return matrix;
  };

  const extractFinalData = (text: string): string => {
    const regex_description = /(\d{8})/g;
    const matches_description = text.match(regex_description);

    const regex_quantity = /x\d+/g;
    const matches_quantity = text.match(regex_quantity);
    const quantity = matches_quantity || "";

    let result = "";

    if (matches_description) {
      for (let i = 0; i < matches_description.length; i++) {
        const code = matches_description[i];
        if (procedures[code as keyof typeof procedures]) {
          const desc = procedures[code as keyof typeof procedures];
          const qty = quantity[i];
          if (desc && qty) {
            result += `${code} ${desc} ${qty} `;
          }
        }
      }
    }

    return result.trim();
  };

  const getResponse = async (input: string) => {
    const combinedText = `Histórico do cliente:\n${newInputRequest}\n————————————————————————————————————\nlaudo:\n${laudoText}`;
    if (input.length === 0) return;
    const requestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ input: combinedText }),
    };

    try {
      setLoading(true);
      setResponseComplete(false);
      setShowInitialPrompt(false);
      setShowGreetings(false);
      const url = `${ROTA_PRODUCAO}/chat/query_lang`;
      const response = await fetch(url, requestInit);

      if (response && response.body) {
        const reader = response.body
          .pipeThrough(new TextDecoderStream())
          .getReader();
        let responseText = "";
        while (true) {
          const { value, done } = await reader.read();
          if (done) break;
          responseText += value;
          setInputResponse((prev) => {
            const newResponse = [...prev];
            if (
              newResponse.length > 0 &&
              newResponse[newResponse.length - 1].query === input
            ) {
              newResponse[newResponse.length - 1].response = responseText;
            } else {
              newResponse.push({ query: input, response: responseText });
            }
            return newResponse;
          });
        }
        const initialItems = extractData(responseText);
        setPatientName(extractName(responseText));
        setCheckboxItems(initialItems);
        setInitialCheckboxItems(initialItems);
        setShowCheckbox(true);
        setResponseComplete(true);
        setShowTextArea(false);
        setUserId(getUserId(responseText));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const delete_engine = async (userId: string) => {
    await fetch(`${ROTA_PRODUCAO}/chat/delete-ragchain`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ session_id: userId }),
    });
  };

  function getUserId(response: string) {
    let firstLine = response.split("\\")[0];

    firstLine = firstLine.replace(/^"|"$/g, "");
    console.log(firstLine);
    return firstLine;
  }

  const saveText = async (input?: string) => {
    try {
      const response = await fetch(`${ROTA_PRODUCAO}/chat/save`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ input }),
      });
      if (response.ok) {
        console.log("Texto salvo com sucesso!");
      } else {
        console.log("Erro ao salvar o texto");
      }
    } catch (error) {
      console.log("Erro ao salvar o texto");
    }
  };

  const handleEnviar = () => {
    saveText(apiInput);
    setApiInput("");
  };

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [apiInput]);

  const handleCheckedItems = (
    checkedItems: Array<{
      code: string;
      description: string;
      quantity: string;
    }>,
    nome_paciente: string,
    laudo: string,
    historico: string
  ) => {
    setCheckedItems(checkedItems);
    sendCheckedItems(checkedItems, nome_paciente, laudo, historico);
    setInputResponse([]);
  };

  const handleSearch = (filteredNames: string[][]) => {
    setSearchResults(filteredNames);
  };

  const handleCheckboxItemsUpdate = (item: string[]) => {
    setCheckboxItems((prevItems) => {
      const existingItemIndex = prevItems.findIndex(
        (prevItem) => prevItem[0] === item[0]
      );
      if (existingItemIndex === -1) {
        const updatedItems = [...prevItems, item];
        handleSearch(updatedItems);

        return updatedItems;
      } else {
        if (item[2] === prevItems[existingItemIndex][2]) {
          toast.error("Esse procedimento já está no pedido.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Slide,
          });
        }
      }
      return prevItems;
    });
  };

  const sendCheckedItems = async (
    checkedItems: Array<{
      code: string;
      description: string;
      quantity: string;
    }>,
    nome_paciente: string,
    laudo: string,
    historico: string
  ) => {
    const requestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        pedidos: checkedItems,
        nome_paciente: nome_paciente,
        laudo: laudo,
        historico: historico,
      }),
    };

    try {
      setLoading(true);
      setResponseComplete(false);
      setShowInitialPrompt(true);
      const url = `${ROTA_PRODUCAO}/chat/output_checklist`;
      const response = await fetch(url, requestInit);

      if (response && response.body) {
        const reader = response.body
          .pipeThrough(new TextDecoderStream())
          .getReader();
        let responseText = "";
        let isFirstChunk = true;
        while (true) {
          const { value, done } = await reader.read();
          if (done) break;
          responseText += value;

          if (isFirstChunk) {
            setLoading(false);
            isFirstChunk = false;
          }

          setInputResponse((prev) => {
            const newResponse = [...prev];
            if (
              newResponse.length > 0 &&
              newResponse[newResponse.length - 1].query ===
                "Esses são os procedimentos!"
            ) {
              newResponse[newResponse.length - 1].response = responseText;
            } else {
              newResponse.push({
                query: "Esses são os procedimentos!",
                response: responseText,
              });
            }

            return newResponse;
          });
        }
        setEditableResponseText(responseText);

        setShowCheckbox(false);
        setResponseComplete(true);
        setshowChatBox(true);
        setSecondResponseComplete(true);
        console.log(userId);
        delete_engine(userId);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const convertCheckboxItemsToString = (items: string[][]): string => {
    return items.map((item) => item.join(" ")).join(" ");
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0] || null;
    setFile(selectedFile);
    if (selectedFile) {
      const fileURL = URL.createObjectURL(selectedFile);
      setFilePreview(fileURL);
    } else {
      setFilePreview(null);
    }
  };

  const extractName = (text: string): string => {
    const regex_patientName = /Nome do paciente:\s*([\wÀ-ÿ\s'-]+)/;
    const patientNameMatch = text.match(regex_patientName);
    const patientName = patientNameMatch ? patientNameMatch[1].trim() : "";

    return patientName;
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleResponseChange = (newText: string) => {
    setEditableResponseText(newText);
  };

  const handleCheckboxItemsAllTime: (items: string[][]) => void = (items) => {
    setCheckboxItems(items);
    console.log(checkboxItems);
  };

  const handleLogClick = async () => {
    const procedimentos_modelo =
      convertCheckboxItemsToString(initialCheckboxItems);
    const user_laudo = `${laudoText}`;
    const user_historico = `${newInputRequest}`;
    const extractedData = extractFinalData(editableResponseText);
    const pedido_cirurgia = `${editableResponseText}`;
    setLoading(true);
    const requestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_historico: user_historico,
        user_laudo: user_laudo,
        procedimentos_modelo: procedimentos_modelo,
        procedimentos_validados: extractedData,
        pedido_cirurgia: pedido_cirurgia,
      }),
    };

    try {
      const url = `${ROTA_PRODUCAO}/chat/log`;
      const response = await fetch(url, requestInit);

      if (response.ok) {
        await handleDownloadPDF(pedido_cirurgia);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadPDF = async (pedido_cirurgia: string) => {
    const requestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        pedido_de_cirurgia: pedido_cirurgia,
        template_type: "mediservice-bradesco",
      }),
    };

    try {
      const url = `${ROTA_PRODUCAO}/chat/exportPDF`;
      const response = await fetch(url, requestInit);
      const blob = await response.blob();
      const urlBlob = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = urlBlob;
      link.download = "pedido_de_cirurgia.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <main className="main-container">
      <Header />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Slide}
      />
      {showGreetings && inputResponse.length === 0 ? (
        <div className="greeting-container">
          <h2 className="greeting-title">Olá, Doutor!</h2>
          <h2 className="greeting-subtitle">
            Forneça as informações sobre o paciente,
            <br />
            para podermos juntos gerar um pedido de cirurgia =)
          </h2>
        </div>
      ) : (
        <>
          {showInitialPrompt &&
            !SecondresponseComplete &&
            inputResponse.map((response, index) => (
              <div key={index} className="response-container">
                <div className="response-item">
                  <div className="response-icon">
                    <FaUserCircle />
                  </div>
                  <div className="response-text">
                    <p>{response.query}</p>
                  </div>
                </div>
                <div className="response-item">
                  <div className="response-icon">
                    <FaRobot />
                  </div>
                  <div className="response-text">
                    {response.response.split("\n").map((paragraph, index) => {
                      if (paragraph.startsWith("###")) {
                        return (
                          <h3 key={index} className="response-title">
                            {paragraph.replace("###", "").trim()}
                          </h3>
                        );
                      } else if (paragraph.startsWith("-")) {
                        return (
                          <li key={index} className="response-list-item">
                            {paragraph}
                          </li>
                        );
                      } else {
                        const formattedParagraph = paragraph
                          .split("**")
                          .map((chunk, chunkIndex) => {
                            return chunkIndex % 2 === 0 ? (
                              <span key={chunkIndex}>{chunk}</span>
                            ) : (
                              <b key={chunkIndex}>{chunk}</b>
                            );
                          });

                        return (
                          <p key={index} className="response-paragraph">
                            {formattedParagraph}
                          </p>
                        );
                      }
                    })}
                  </div>
                </div>
                <br />
              </div>
            ))}
          {responseComplete && showCheckbox && checkboxItems.length > 0 && (
            <div className="checkbox-container">
              <h1 className="checkbox-header">
                Esses são os códigos! Adicione ou remova.
              </h1>
              <SearchBar
                onSearch={handleSearch}
                onCheckboxItemsUpdate={handleCheckboxItemsUpdate}
              />
              <CheckBox
                texts={checkboxItems}
                onSendCheckedItems={handleCheckedItems}
                nome_paciente={patientName}
                laudo={laudoText}
                historico={newInputRequest}
                onItemsChange={handleCheckboxItemsAllTime}
              />
            </div>
          )}
        </>
      )}
      {showTextArea && (
        <div className="textarea-container">
          {/* <div className="reset-button-container">
            <FaSync
              className="text-gray-800 cursor-pointer"
              onClick={reset}
              size={20}
            />
          </div>*/}
          <h1>Histórico do paciente:</h1>
          <div className="textarea-wrapper">
            <textarea
              disabled={loading}
              className="laudo-textarea"
              onChange={(e) => setnewInputRequest(e.target.value)}
              value={newInputRequest}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  getResponse(newInputRequest);
                }
              }}
            />
          </div>
          <h1>Laudo:</h1>
          <div className="textarea-wrapper">
            <textarea
              className="laudo-textarea"
              onChange={(e) => setLaudoText(e.target.value)}
              value={laudoText}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  getResponse(newInputRequest);
                }
              }}
            />
            {/* <label htmlFor="file-input">
              <IoDocumentAttachOutline className='attach-icon'/>
            </label>
          <input type="file" id="file-input" className="file-input" onChange={handleFileChange} />
          {filePreview && <div className="file-box"><img src={filePreview} alt="Preview" style={{ maxWidth: '30px', maxHeight: '30px' }} /></div>}*/}
          </div>
          <button
            className="enviar-query"
            onClick={() => getResponse(`${newInputRequest} ${laudoText}`)}
          >
            Gerar Sugestões de Procedimento
            {loading && <AiOutlineLoading3Quarters className="loading-icon" />}
          </button>
        </div>
      )}

      {!showTextArea && loading && !responseComplete && (
        <div className="centered-loading">
          <AiOutlineLoading3Quarters className="loading-icon-centered" />
        </div>
      )}

      {!showTextArea && showChatBox && (
        <div className="edit-container-chat">
          <div className="box-text">
            <div className="response-container">
              <div className="response-item">
                <button className="editar-button" onClick={handleEditClick}>
                  {isEditing ? "Salvar" : "Editar"}
                </button>
                <div className="response-text">
                  {isEditing ? (
                    <textarea
                      value={editableResponseText}
                      onChange={(e) => handleResponseChange(e.target.value)}
                      className="editar-textarea"
                    />
                  ) : (
                    // Assuming `editableResponseText` is a single string
                    editableResponseText
                      .split("\n")
                      .map((paragraph, paragraphIndex) => {
                        if (paragraph.startsWith("###")) {
                          return (
                            <h3 key={paragraphIndex} className="response-title">
                              {paragraph.replace("###", "").trim()}
                            </h3>
                          );
                        } else if (paragraph.startsWith("-")) {
                          return (
                            <li
                              key={paragraphIndex}
                              className="response-list-item"
                            >
                              {paragraph}
                            </li>
                          );
                        } else {
                          const formattedParagraph = paragraph
                            .split("**")
                            .map((chunk, chunkIndex) => {
                              return chunkIndex % 2 === 0 ? (
                                <span key={chunkIndex}>{chunk}</span>
                              ) : (
                                <b key={chunkIndex}>{chunk}</b>
                              );
                            });

                          return (
                            <p
                              key={paragraphIndex}
                              className="response-paragraph"
                            >
                              {formattedParagraph}
                            </p>
                          );
                        }
                      })
                  )}
                </div>
              </div>
            </div>
          </div>
          <button className="button-arquivo" onClick={handleLogClick}>
            Gerar arquivo
            {loading && (
              <AiOutlineLoading3Quarters className="loading-icon-new" />
            )}
          </button>
        </div>
      )}

      <div className="footer"></div>
    </main>
  );
};

export default LangChainPage;

import "./styles.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const GetMetadas: React.FC = () => {
  const [isNameFocused, setIsNameFocused] = useState(false);
  const [isCrmFocused, setIsCrmFocused] = useState(false);
  const [metadata, setMetadata] = useState({
    name: "",
    crm: "",
  });
  const [status, setStatus] = useState(true);
  const navigate = useNavigate();

  useEffect(()=>{
    if (metadata.name !== "" && metadata.crm !== ""){
        setStatus(false);
    }else{
      setStatus(true);
    }
  }, [metadata])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    setMetadata((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const makeRedirect = () => {
    if (!status) {
      setMetadata({
        name: "",
        crm: "",
      });
      setStatus(true);
      navigate('/novo-pedido');
    }
  };

  return (
    <div className="get_metadata_container">
      <div className="get_metadata_content">
        <div className="get_metadata_title">
          <h2>Insira alguns dados adicionais</h2>
        </div>
        <div className="get_metadata_form">
          <div className="get_metadata_input">
            <div className="get_metadata_input_components">
              <label
                htmlFor="name"
                style={{
                  top: isNameFocused || metadata.name ? "-18px" : "1px",
                  fontSize: isNameFocused || metadata.name ? "12px" : "15px",
                  color: isNameFocused || metadata.name ? "#4B73FF" : "#a9a9a9",
                }}
              >
                Digite seu nome completo
              </label>
              <input
                type="text"
                id="name"
                autoComplete="off"
                value={metadata.name}
                onFocus={() => setIsNameFocused(true)}
                onBlur={() => setIsNameFocused(false)}
                onChange={handleInputChange}
                style={{
                  borderColor:
                    isNameFocused || metadata.name ? "#4B73FF" : "#a9a9a9",
                }}
              />
            </div>
          </div>
          <div className="get_metadata_input">
            <div className="get_metadata_input_components">
              <label
                htmlFor="crm"
                style={{
                  top: isCrmFocused || metadata.crm ? "-18px" : "1px",
                  fontSize: isCrmFocused || metadata.crm ? "12px" : "15px",
                  color: isCrmFocused || metadata.crm ? "#4B73FF" : "#a9a9a9",
                }}
              >
                Digite seu CRM
              </label>
              <input
                type="text"
                id="crm"
                autoComplete="off"
                value={metadata.crm}
                onFocus={() => setIsCrmFocused(true)}
                onBlur={() => setIsCrmFocused(false)}
                onChange={handleInputChange}
                style={{
                  borderColor:
                    isCrmFocused || metadata.crm ? "#4B73FF" : "#a9a9a9",
                }}
              />
            </div>
          </div>
          <button
            disabled={status}
            onClick={makeRedirect}
            style={{
              opacity: status ? "0.5" : "1",
            }}
          >
            Confirmar
          </button>
        </div>
      </div>
    </div>
  );
};

export default GetMetadas;

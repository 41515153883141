import React, { useState, useRef, useEffect, ChangeEvent } from 'react';
import "./styles.css";



const Header: React.FC = () => {
  
  return (
    <div>
        <h1 className="header">Vytalia</h1>
    </div>
  );
};

export default Header;

import { useEffect, useRef, useState } from "react";
import { FaLocationArrow, FaRobot, FaSync, FaUserCircle } from "react-icons/fa";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import CheckBox from "../../components/Checkbox";
import SearchBar from "../../components/SearchBar";
import { procedures } from "../../components/SearchBar/proceduresData";
import "./styles.css";
import { IoSend } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { IoDocumentAttachOutline } from "react-icons/io5";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import FooterMenu from "../../components/FooterMenu";
import { useToken } from "../../services/api";

interface InputResponse {
  query: string;
  response: string;
}

interface CheckBoxProps {
  CheckBoxItems: string[][];
}

const NewOrderPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [newInputRequest, setnewInputRequest] = useState("");
  const [apiInput, setApiInput] = useState("");
  const [inputResponse, setInputResponse] = useState<Array<InputResponse>>([]);
  const [responseComplete, setResponseComplete] = useState(false);
  const [SecondresponseComplete, setSecondResponseComplete] = useState(false);
  const [checkboxItems, setCheckboxItems] = useState<string[][]>([]);
  const [checkedItems, setCheckedItems] = useState<
    Array<{ code: string; description: string; quantity: string }>
  >([]);
  const [searchResults, setSearchResults] = useState<string[][]>([]);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [showInitialPrompt, setShowInitialPrompt] = useState(true);
  const [laudoText, setLaudoText] = useState("");
  const [talkingText, setTalkingText] = useState("");
  const [showTextArea, setShowTextArea] = useState(true);
  const [showGreetings, setShowGreetings] = useState(true);
  const [showChatBox, setshowChatBox] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const [initialCheckboxItems, setInitialCheckboxItems] = useState<string[][]>(
    []
  );
  const [file, setFile] = useState<File | null>(null);
  const [filePreview, setFilePreview] = useState<string | null>(null);
  const [patientName, setPatientName] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editableResponseText, setEditableResponseText] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  const [isFocusedRequest, setIsFocusedRequest] = useState(false);
  const [isFocusedLaudo, setIsFocusedLaudo] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  const { getToken } = useToken();

  useEffect(() => {
    const fetchToken = async () => {
      const fetchedToken = await getToken();
      setToken(fetchedToken);
    };
    fetchToken();
  }, [getToken]);

  const extractData = (text: string): string[][] => {
    const regex_description = /(\d{8})/g;
    const matches_description = text.match(regex_description);

    const regex_quantity = /x\d+/g;
    const matches_quantity = text.match(regex_quantity);
    const quantity = matches_quantity || [];

    const matrix: string[][] = [];

    if (matches_description) {
      for (let i = 0; i < matches_description.length; i++) {
        const code = matches_description[i];
        if (procedures[code as keyof typeof procedures]) {
          const desc = procedures[code as keyof typeof procedures];
          const qty = quantity[i];
          if (desc && qty) {
            matrix.push([code, desc, qty]);
          }
        }
      }
    }

    return matrix;
  };

  const extractFinalData = (text: string): string => {
    const regex_description = /(\d{8})/g;
    const matches_description = text.match(regex_description);

    const regex_quantity = /x\d+/g;
    const matches_quantity = text.match(regex_quantity);
    const quantity = matches_quantity || "";

    let result = "";

    if (matches_description) {
      for (let i = 0; i < matches_description.length; i++) {
        const code = matches_description[i];
        if (procedures[code as keyof typeof procedures]) {
          const desc = procedures[code as keyof typeof procedures];
          const qty = quantity[i];
          if (desc && qty) {
            result += `${code} ${desc} ${qty} `;
          }
        }
      }
    }

    return result.trim();
  };

  const getResponse = async (input: string) => {
    const combinedText = `Histórico do cliente:\n${newInputRequest}\n————————————————————————————————————\nlaudo:\n${laudoText}`;
    if (input.length === 0) return;
    const requestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ input: combinedText }),
    };

    try {
      setLoading(true);
      setResponseComplete(false);
      setShowInitialPrompt(false);
      setShowGreetings(false);
      const url = "https://api.letsdoc.ai/chat/query_stream";
      const response = await fetch(url, requestInit);

      if (response && response.body) {
        const reader = response.body
          .pipeThrough(new TextDecoderStream())
          .getReader();
        let responseText = "";
        while (true) {
          const { value, done } = await reader.read();
          if (done) break;
          responseText += value;
          setInputResponse((prev) => {
            const newResponse = [...prev];
            if (
              newResponse.length > 0 &&
              newResponse[newResponse.length - 1].query === input
            ) {
              newResponse[newResponse.length - 1].response = responseText;
            } else {
              newResponse.push({ query: input, response: responseText });
            }
            return newResponse;
          });
        }
        const initialItems = extractData(responseText);
        setPatientName(extractName(responseText));
        console.log(responseText);
        console.log(setPatientName(extractName(responseText)));
        setCheckboxItems(initialItems);
        setInitialCheckboxItems(initialItems);
        setShowCheckbox(true);
        setResponseComplete(true);
        setShowTextArea(false);
        setUserId(getUserId(responseText));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const delete_engine = async (userId: string) => {
    await fetch("https://api.letsdoc.ai/chat/delete-engine", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ session_id: userId }),
    });
  };

  function getUserId(response: string) {
    const firstLine = response.split("\n")[0];
    return firstLine;
  }

  const saveText = async (input?: string) => {
    try {
      const response = await fetch("https://api.letsdoc.ai/chat/save", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ input }),
      });
      if (response.ok) {
        console.log("Texto salvo com sucesso!");
      } else {
        console.log("Erro ao salvar o texto");
      }
    } catch (error) {
      console.log("Erro ao salvar o texto");
    }
  };

  const handleEnviar = () => {
    saveText(apiInput);
    setApiInput("");
  };

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [apiInput]);

  const handleCheckedItems = (
    checkedItems: Array<{
      code: string;
      description: string;
      quantity: string;
    }>,
    nome_paciente: string,
    laudo: string,
    historico: string
  ) => {
    setCheckedItems(checkedItems);
    sendCheckedItems(checkedItems, nome_paciente, laudo, historico);
    setInputResponse([]);
  };

  const handleSearch = (filteredNames: string[][]) => {
    setSearchResults(filteredNames);
  };

  const handleCheckboxItemsUpdate = (item: string[]) => {
    setCheckboxItems((prevItems) => {
      const existingItemIndex = prevItems.findIndex(
        (prevItem) => prevItem[0] === item[0]
      );
      if (existingItemIndex === -1) {
        const updatedItems = [...prevItems, item];
        handleSearch(updatedItems);

        return updatedItems;
      } else {
        if (item[2] === prevItems[existingItemIndex][2]) {
          toast.error("Esse procedimento já está no pedido.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Slide,
          });
        }
      }
      return prevItems;
    });
  };

  const sendCheckedItems = async (
    checkedItems: Array<{
      code: string;
      description: string;
      quantity: string;
    }>,
    nome_paciente: string,
    laudo: string,
    historico: string
  ) => {
    const requestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        pedidos: checkedItems,
        nome_paciente: nome_paciente,
        laudo: laudo,
        historico: historico,
      }),
    };

    try {
      setLoading(true);
      setResponseComplete(false);
      setShowInitialPrompt(true);
      const url = "https://api.letsdoc.ai/chat/output_checklist";
      const response = await fetch(url, requestInit);

      if (response && response.body) {
        const reader = response.body
          .pipeThrough(new TextDecoderStream())
          .getReader();
        let responseText = "";
        let isFirstChunk = true;
        while (true) {
          const { value, done } = await reader.read();
          if (done) break;
          responseText += value;

          if (isFirstChunk) {
            setLoading(false);
            isFirstChunk = false;
          }

          setInputResponse((prev) => {
            const newResponse = [...prev];
            if (
              newResponse.length > 0 &&
              newResponse[newResponse.length - 1].query ===
                "Esses são os procedimentos!"
            ) {
              newResponse[newResponse.length - 1].response = responseText;
            } else {
              newResponse.push({
                query: "Esses são os procedimentos!",
                response: responseText,
              });
            }

            return newResponse;
          });
        }
        setEditableResponseText(responseText);

        setShowCheckbox(false);
        setResponseComplete(true);
        setshowChatBox(true);
        setSecondResponseComplete(true);
        delete_engine(userId);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const convertCheckboxItemsToString = (items: string[][]): string => {
    return items.map((item) => item.join(" ")).join(" ");
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0] || null;
    setFile(selectedFile);
    if (selectedFile) {
      const fileURL = URL.createObjectURL(selectedFile);
      setFilePreview(fileURL);
    } else {
      setFilePreview(null);
    }
  };

  const extractName = (text: string): string => {
    const regex_patientName = /Nome do paciente:\s*([\w\s]+)/;
    const patientNameMatch = text.match(regex_patientName);
    const patientName = patientNameMatch
      ? patientNameMatch[1].replace(/\s+$/, "")
      : "";

    return patientName;
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleResponseChange = (newText: string) => {
    setEditableResponseText(newText);
  };

  const handleCheckboxItemsAllTime: (items: string[][]) => void = (items) => {
    setCheckboxItems(items);
    console.log(checkboxItems);
  };

  const handleLogClick = async () => {
    const procedimentos_modelo =
      convertCheckboxItemsToString(initialCheckboxItems);
    const user_laudo = `${laudoText}`;
    const user_historico = `${newInputRequest}`;
    const extractedData = extractFinalData(editableResponseText);
    const pedido_cirurgia = `${editableResponseText}`;
    setLoading(true);
    const requestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        user_historico: user_historico,
        user_laudo: user_laudo,
        procedimentos_modelo: procedimentos_modelo,
        procedimentos_validados: extractedData,
        pedido_cirurgia: pedido_cirurgia,
      }),
    };

    try {
      const url = "https://api.letsdoc.ai/chat/log";
      const response = await fetch(url, requestInit);

      if (response.ok) {
        await handleDownloadPDF(pedido_cirurgia);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadPDF = async (pedido_cirurgia: string) => {
    const requestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        pedido_de_cirurgia: pedido_cirurgia,
        template_type: "mediservice-bradesco",
      }),
    };

    try {
      const url = "https://api.letsdoc.ai/chat/exportPDF";
      const response = await fetch(url, requestInit);
      const blob = await response.blob();
      const urlBlob = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = urlBlob;
      link.download = "pedido_de_cirurgia.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = () => {
    setLoading(false); // Para o estado de carregamento
    setInputResponse([]); // Reseta as respostas
    setShowTextArea(true); // Volta para a tela de entrada
    setResponseComplete(false); // Reseta o estado de resposta completa
    setShowCheckbox(false); // Oculta o checkbox
  };

  return (
    <main className="support-main-container">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Slide}
      />
      <div className="support-greeting-container">
        <h2 className="support-greeting-title">Novo Pedido de Cirurgia</h2>
      </div>
      <>
        {showInitialPrompt &&
          !SecondresponseComplete &&
          inputResponse.map((response, index) => (
            <div key={index} className="support-response-container">
              <div className="support-response-item">
                <div className="support-response-icon">
                  <FaUserCircle />
                </div>
                <div className="support-response-text">
                  <p>{response.query}</p>
                </div>
              </div>
              <div className="support-response-item">
                <div className="support-response-icon">
                  <FaRobot />
                </div>
                <div className="support-response-text">
                  {response.response.split("\n").map((paragraph, index) => {
                    if (paragraph.startsWith("###")) {
                      return (
                        <h3 key={index} className="support-response-title">
                          {paragraph.replace("###", "").trim()}
                        </h3>
                      );
                    } else if (paragraph.startsWith("-")) {
                      return (
                        <li key={index} className="support-response-list-item">
                          {paragraph}
                        </li>
                      );
                    } else {
                      const formattedParagraph = paragraph
                        .split("**")
                        .map((chunk, chunkIndex) => {
                          return chunkIndex % 2 === 0 ? (
                            <span key={chunkIndex}>{chunk}</span>
                          ) : (
                            <b key={chunkIndex}>{chunk}</b>
                          );
                        });

                      return (
                        <p key={index} className="support-response-paragraph">
                          {formattedParagraph}
                        </p>
                      );
                    }
                  })}
                </div>
              </div>
              <br />
            </div>
          ))}
        {responseComplete && showCheckbox && checkboxItems.length > 0 && (
          <div className="support-checkbox-container">
            <h1 className="support-checkbox-header">
              Esses são os códigos! Adicione ou remova.
            </h1>
            <SearchBar
              onSearch={handleSearch}
              onCheckboxItemsUpdate={handleCheckboxItemsUpdate}
            />
            <CheckBox
              texts={checkboxItems}
              onSendCheckedItems={handleCheckedItems}
              nome_paciente={patientName}
              laudo={laudoText}
              historico={newInputRequest}
              onItemsChange={handleCheckboxItemsAllTime}
            />
          </div>
        )}
      </>
      {showTextArea && (
        <div className="support-form-container">
          <div
            className={`support-input-group ${
              isFocusedRequest || newInputRequest ? "support-focused" : ""
            }`}
          >
            <label className="support-input-label">1</label>
            <div className="support-input-wrapper">
              <label className="support-input-title">
                Digite um breve histórico do paciente
              </label>
              <textarea
                disabled={loading}
                className="support-input-field"
                onChange={(e) => setnewInputRequest(e.target.value)}
                value={newInputRequest}
                onFocus={() => setIsFocusedRequest(true)}
                onBlur={() => setIsFocusedRequest(false)}
              />
              <div className="support-underline"></div>
            </div>
          </div>

          <div
            className={`support-input-group ${
              isFocusedLaudo || laudoText ? "support-focused" : ""
            }`}
          >
            <label className="support-input-label">2</label>
            <div className="support-input-wrapper">
              <label className="support-input-title">Digite o laudo</label>
              <textarea
                disabled={loading}
                className="support-input-field"
                onChange={(e) => setLaudoText(e.target.value)}
                value={laudoText}
                onFocus={() => setIsFocusedLaudo(true)}
                onBlur={() => setIsFocusedLaudo(false)}
              />
              <div className="support-underline"></div>
            </div>
          </div>

          <div className="support-analisar-button-container">
            <button
              className={`support-analisar-button ${
                newInputRequest && laudoText
                  ? "support-enabled"
                  : "support-disabled"
              }`}
              onClick={() => getResponse(`${newInputRequest} ${laudoText}`)}
              disabled={!newInputRequest || !laudoText}
            >
              Analisar
            </button>
          </div>
        </div>
      )}
      {!showTextArea && loading && !responseComplete && (
        <div className="support-centered-loading">
          <div className="support-loading-content">
            <div className="support-loading-wrapper">
              <img
                src="/loading.svg"
                alt="Loading circle"
                className="support-loading-icon"
              />
              <img
                src="/robot.svg"
                alt="Loading robot"
                className="support-robot-icon"
              />
            </div>
            <p className="support-loading-text">Buscando códigos de cirurgia</p>
            <button className="support-cancel-button" onClick={handleCancel}>
              Cancelar
            </button>
          </div>
        </div>
      )}

      {!showTextArea && showChatBox && (
        <div className="support-edit-container-chat">
          <div className="support-box-text">
            <div className="support-response-container">
              <div className="support-response-item">
                <button
                  className="support-editar-button"
                  onClick={handleEditClick}
                >
                  {isEditing ? "Salvar" : "Editar"}
                </button>
                <div className="support-response-text">
                  {isEditing ? (
                    <textarea
                      value={editableResponseText}
                      onChange={(e) => handleResponseChange(e.target.value)}
                      className="support-editar-textarea"
                    />
                  ) : (
                    // Assuming `editableResponseText` is a single string
                    editableResponseText
                      .split("\n")
                      .map((paragraph, paragraphIndex) => {
                        if (paragraph.startsWith("###")) {
                          return (
                            <h3
                              key={paragraphIndex}
                              className="support-response-title"
                            >
                              {paragraph.replace("###", "").trim()}
                            </h3>
                          );
                        } else if (paragraph.startsWith("-")) {
                          return (
                            <li
                              key={paragraphIndex}
                              className="support-response-list-item"
                            >
                              {paragraph}
                            </li>
                          );
                        } else {
                          const formattedParagraph = paragraph
                            .split("**")
                            .map((chunk, chunkIndex) => {
                              return chunkIndex % 2 === 0 ? (
                                <span key={chunkIndex}>{chunk}</span>
                              ) : (
                                <b key={chunkIndex}>{chunk}</b>
                              );
                            });

                          return (
                            <p
                              key={paragraphIndex}
                              className="support-response-paragraph"
                            >
                              {formattedParagraph}
                            </p>
                          );
                        }
                      })
                  )}
                </div>
              </div>
            </div>
          </div>
          <button className="support-button-arquivo" onClick={handleLogClick}>
            Gerar arquivo
            {loading && (
              <AiOutlineLoading3Quarters className="support-loading-icon-new" />
            )}
          </button>
        </div>
      )}
      <div>
        <FooterMenu selectedButton={currentPath} />
      </div>
    </main>
  );
};

export default NewOrderPage;

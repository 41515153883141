export const  procedures = {
    "30201012": "Biópsia de lábio",
  "30201020": "Excisão com plástica de vermelhão",
  "30201039": "Excisão com reconstrução à custa de retalhos",
  "30201047": "Excisão com reconstrução total",
  "30201055": "Excisão em cunha",
  "30201063": "Frenotomia labial",
  "30201071": "Queiloplastia para fissura labial unilateral - por estágio",
  "30201080": "Reconstrução de sulco gengivo-labial",
  "30201098": "Reconstrução total do lábio",
  "30201101": "Tratamento cirúrgico da macrostomia",
  "30201110": "Tratamento cirúrgico da microstomia",
  "30202019": "Alongamento cirúrgico do palato mole",
  "30202027": "Biópsia de boca",
  "30202035": "Excisão de lesão maligna com reconstrução à custa de retalhos locais",
  "30202043": "Excisão de tumor de boca com mandibulectomia",
  "30202051": "Exérese de tumor e enxerto cutâneo ou mucoso",
  "30202060": "Fístula orofacial - tratamento cirúrgico",
  "30202078": "Glossectomia subtotal ou total, com ou sem mandibulectomia",
  "30202094": "Palatoplastia com enxerto ósseo",
  "30202108": "Palatoplastia com retalho faríngeo",
  "30202116": "Palatoplastia com retalho miomucoso",
  "30202124": "Palatoplastia parcial",
  "30202132": "Palatoplastia total",
  "30202086": "Palato-queiloplastia unilateral",
  "30202140": "Plástica do ducto parotídeo",
  "30203031": "Biópsia de língua",
  "30203015": "Frenotomia lingual",
  "30203023": "Tumor de língua - tratamento cirúrgico",
  "30204011": "Biópsia de glândula salivar",
  "30204020": "Excisão de glândula submandibular",
  "30204038": "Exérese de rânula ou mucocele",
  "30204070": "Parotidectomia com reconstrução do nervo facial",
  "30204046": "Parotidectomia parcial com conservação do nervo facial",
  "30204062": "Parotidectomia total com conservação do nervo facial",
  "30204089": "Parotidectomia total com sacrificio do nervo facial, sem reconstrução",
  "30204097": "Plastia de ducto salivar ou exérese de cálculo ou de rânula salivar",
  "30204100": "Ressecção de tumor de glândula sublingual",
  "30205018": "Abscesso faríngeo - qualquer área",
  "30205026": "Adeno tonsilectomia - revisão cirúrgica",
  "30205042": "Adenoidectomia",
  "30205271": "Adenoidectomia por videoendoscopia",
  "30205050": "Amigdalectomia das palatinas",
  "30205069": "Amigdalectomia lingual",
  "30205077": "Biópsia do cavum, orofaringe ou hipofaringe",
  "30205085": "Cauterização (qualquer técnica) por sessão",
  "30205093": "Corpo estranho de faringe - retirada em consultório",
  "30205107": "Corpo estranho de faringe - retirada sob anestesia geral",
  "30205115": "Criptólise amigdaliana",
  "30205140": "Faringolaringectomia",
  "30205158": "Faringolaringoesofagectomia total",
  "30205166": "Ressecção de nasoangiofibroma",
  "30205280": "Ressecção de nasoangiofibroma por videoendoscopia",
  "30205174": "Ressecção de tumor de faringe (via bucal ou nasal)",
  "30205182": "Ressecção de tumor de faringe com acesso por faringotomia ou por retalho jugal",
  "30205190": "Ressecção de tumor de faringe com mandibulectomia",
  "30205204": "Ressecção de tumor de faringe por mandibulotomia",
  "30205212": "Ressecção de tumor de nasofaringe via endoscópica",
  "30205220": "Tonsilectomia a laser",
  "30205239": "Tumor de boca ou faringe - ressecção",
  "30205247": "Uvulopalatofaringoplastia (qualquer técnica)",
  "30205263": "Uvulopalatofaringoplastia por radiofrequência",
  "30206014": "Alargamento de traqueostomia",
  "30206022": "Aritenoidectomia microcirúrgica",
  "30206030": "Aritenoidectomia ou aritenopexia via externa",
  "30206049": "Confecção de fístula tráqueo-esofágica para prótese fonatória com miotomia faríngea",
  "30206065": "Exérese de tumor por via endoscópica",
  "30206103": "Injeção intralaríngea de toxina botulínica",
  "30206120": "Laringectomia parcial",
  "30206138": "Laringectomia total",
  "30206170": "Laringofissura (inclusive com cordectomia)",
  "30206200": "Laringotraqueoplastia",
  "30206219": "Microcirurgia com laser para remoção de lesões malignas",
  "30206227": "Microcirurgia com uso de laser para ressecção de lesões benignas",
  "30206235": "Microcirurgia para decorticação ou tratamento de edema de Reinke",
  "30206243": "Microcirurgia para remoção de cisto ou lesão intracordal",
  "30206251": "Microcirurgia para ressecção de papiloma",
  "30206260": "Microcirurgia para ressecção de pólipo, nódulo ou granuloma",
  "30206278": "Microcirurgia para tratamento de paralisia de prega vocal (inclui injeção de materiais)",
  "30206294": "Reconstrução para fonação após laringectomia",
  "30206308": "Tiroplastia tipo 1 com rotação de aritenóide",
  "30206316": "Tiroplastia tipo 1 simples",
  "30206324": "Tiroplastia tipo 2 ou 3",
  "30206359": "Tratamento cirúrgico da estenose laringo-traqueal",
  "30206367": "Tratamento cirúrgico de trauma laríngeo (agudo)",
  "30207088": "Fratura  simples de mandíbula com contenção e bloqueio intermaxilar eventual",
  "30207118": "Fratura cominutiva de mandíbula - redução cirúrgica com fixação óssea e bloqueio intermaxilar eventual",
  "30207142": "Fratura de maxila, tipo Lefort I e II - redução e aplicação de levantamento zigomático-maxilar com bloqueio intermaxilar eventual",
  "30207150": "Fratura de maxila, tipo Lefort III - redução e aplicação de levantamento crânio-maxilar com bloqueio intermaxilar eventual",
  "30207070": "Fratura do arco zigomático - redução cirúrgica com fixação",
  "30207061": "Fratura do arco zigomático - redução instrumental sem fixação",
  "30207169": "Fratura Lefort I - fixação cirúrgica com síntese óssea, levantamento e bloqueio intermaxilar eventual",
  "30207177": "Fratura Lefort II - fixação cirúrgica com síntese óssea, levantamento e bloqueio intermaxilar eventual",
  "30207185": "Fratura Lefort III - fixação cirúrgica com síntese óssea, levantamento crânio-maxilar e bloqueio intermaxilar eventual",
  "30207100": "Fratura naso etmóido órbito-etmoidal",
  "30207096": "Fratura simples de mandíbula - redução cirúrgica com fixação óssea e bloqueio intermaxilar eventual",
  "30207134": "Fraturas alveolares - fixação com aparelho e contenção",
  "30207126": "Fraturas complexas de mandíbula - redução cirúrgica com fixação óssea e eventual bloqueio intermaxilar",
  "30207207": "Fraturas complexas do terço médio da face, fixação cirúrgica com síntese, levantamento crânio-maxilar, enxerto ósseo, halo craniano eventual",
  "30207193": "Fraturas múltiplas de terço médio da face: fixação cirúrgica com síntese óssea, levantamento crânio maxilar e bloqueio intermaxilar",
  "30207045": "Redução de fratura de seio frontal (acesso coronal)",
  "30207037": "Redução de fratura de seio frontal (acesso frontal)",
  "30207029": "Redução de fratura do malar (com fixação)",
  "30207010": "Redução de fratura do malar (sem fixação)",
  "30207231": "Redução de luxação do ATM",
  "30207215": "Retirada dos meios de fixação (na face)",
  "30207223": "Tratamento conservador de fratura de ossos",
  "30208017": "Artroplastia para luxação recidivante da articulação têmporo-mandibular",
  "30208025": "Osteoplastia para prognatismo, micrognatismo ou laterognatismo",
  "30208084": "Osteotomia crânio-maxilares complexas",
  "30208157": "Osteotomia da mandíbula e/ou maxila com aplicação nde osteodistrator",
  "30208050": "Osteotomia tipo Lefort I",
  "30208068": "Osteotomia tipo Lefort II",
  "30208076": "Osteotomia tipo Lefort III - extracraniana",
  "30208033": "Osteotomias alvéolo palatinas",
  "30208041": "Osteotomias segmentares da maxila ou malar",
  "30208106": "Reconstrução parcial da mandíbula com enxerto ósseo",
  "30208114": "Reconstrução total de mandíbula com prótese e ou enxerto ósseo",
  "30208092": "Redução simples da luxação da articulação têmporo-mandibular com fixação intermaxilar",
  "30208130": "Translocação etmóido orbital para tratamento do hipertelorismo    miocutâneo associado a expansor de tecido - por lado",
  "30208122": "Tratamento cirúrgico de anquilose da articulação têmporo-mandibular",
  "30209056": "Correção cirúrgica de depressão (afundamento) da região frontal",
  "30209048": "Osteoplastias da órbita",
  "30209021": "Osteoplastias de mandíbula",
  "30209030": "Osteoplastias do arco zigomático",
  "30209013": "Osteoplastias etmóido orbitais",
  "30210020": "Correção de tumores, cicatrizes ou ferimentos com o auxílio de expansores de tecidos - por estágio",
  "30210127": "Exérese de tumor benigno, cisto ou fístula",
  "30210119": "Exérese de tumor maligno de pele",
  "30210011": "Hemiatrofia facial, correção com enxerto de gordura ou implante",
  "30210054": "Paralisia facial - reanimação com o músculo temporal (região oral), com neurotização",
  "30210038": "Paralisia facial - reanimação com o músculo temporal (região oral), sem neurotização",
  "30210062": "Paralisia facial - reanimação com o músculo temporal (região orbital e oral), com neurotização",
  "30210046": "Paralisia facial - reanimação com o músculo temporal (região orbital), sem neurotização",
  "30210089": "Reconstrução com retalho axial da artéria temporal superficial",
  "30210070": "Reconstrução com retalhos axiais supra-orbitais e supratrocleares",
  "30210097": "Reconstrução com retalhos em VY de pedículo subarterial",
  "30210100": "Reconstrução com rotação do músculo temporal",
  "30211018": "Biópsia de mandíbula",
  "30211042": "Hemimandibulectomia ou ressecção segmentar ou seccional da mandíbula",
  "30211050": "Mandibulectomia total",
  "30211034": "Ressecção de tumor de mandíbula com desarticulação de ATM",
  "30212014": "Cervicotomia exploradora",
  "30212022": "Drenagem de abscesso cervical profundo",
  "30212049": "Esvaziamento cervical bilateral",
  "30212030": "Esvaziamento cervical unilateral",
  "30212057": "Esvaziamento recorrencial (especificar o lado)",
  "30212065": "Exérese de cisto branquial",
  "30212073": "Exérese de cisto tireoglosso",
  "30212081": "Exérese de tumor benigno, cisto ou fístula cervical",
  "30212090": "Linfadenectomia profunda - biópsia excisional de linfonodo cervical (único ou múltiplo)",
  "30212103": "Linfadenectomia superficial - biópsia incisional de linfonodo cervical",
  "30212111": "Neuroblastoma cervical - exérese",
  "30212120": "Punção-biópsia de pescoço",
  "30212138": "Reconstrução de esôfago cervical",
  "30212146": "Ressecção de tumor de corpo carotídeo",
  "30212154": "Retração cicatricial cervical - por estágio",
  "30212162": "Retração cicatricial cervical com emprego de expansores de tecido - por estágio",
  "30212170": "Torcicolo congênito - tratamento cirúrgico",
  "30212189": "Tratamento cirúrgico da lipomatose cervical",
  "30212197": "Tratamento cirúrgico de fístula com retalho cutâneo",
  "30213010": "Biópsia de tireóide",
  "30213029": "Bócio mergulhante: extirpação por acesso cérvico-torácico",
  "30213037": "Istmectomia ou nodulectomia",
  "30213045": "Tireoidectomia parcial",
  "30213053": "Tireoidectomia total",
  "30214017": "Biópsia de paratireóide",
  "30214025": "Paratireoidectomia com toracotomia",
  "30214068": "Paratireoidectomia total com reimplante primário de paratireóide",
  "30214033": "Reimplante de paratireóide",
  "30214041": "Tratamento cirúrgico do hiperparatireoidismo primário de glândula única",
  "30214050": "Tratamento cirúrgico do hiperparatireoidismo secundário, paratireoidectomia subtotal ou de múltiplas paratireóides",
  "30215013": "Cranioplastia",
  "30215021": "Craniotomia descompressiva",
  "30215030": "Craniotomia para tumores ósseos",
  "30215048": "Reconstrução craniana ou craniofacial",
  "30215056": "Retirada de cranioplastia",
  "30215072": "Tratamento cirúrgico da craniossinostose",
  "30215080": "Tratamento cirúrgico da fratura do crânio - afundamento",
  "30215099": "Tratamento cirúrgico da osteomielite de crânio",
  "30401011": "Biópsia de pavilhão auricular",
  "30401020": "Exérese de tumor com abordagem craniofacial oncológica pavilhão auricular (tempo facial)",
  "30401038": "Exérese de tumor com fechamento primário",
  "30401046": "Outros defeitos congênitos que não a microtia",
  "30401054": "Reconstrução  de orelha - retoques",
  "30401062": "Reconstrução de unidade anatômica do pavilhão auricular - por estágio",
  "30401070": "Reconstrução total de orelha - único estágio",
  "30401089": "Ressecção de tumor de pavilhão auricular, incluindo parte do osso temporal",
  "30401097": "Ressecção subtotal ou total de orelha",
  "30401100": "Tratamento cirúrgico de sinus pré-auricular",
  "30402018": "Aspiração auricular",
  "30402026": "Biópsia (orelha externa)",
  "30402034": "Cisto pré-auricular (coloboma auris) - exérese-unilateral",
  "30402042": "Corpos estranhos, pólipos ou biópsia - em consultório",
  "30402050": "Corpos estranhos, pólipos ou biópsia - em hospital sob anestesia geral",
  "30402069": "Estenose de conduto auditivo externo - correção",
  "30402077": "Furúnculo - drenagem (ouvido)",
  "30402085": "Pericondrite de pavilhão - tratamento cirúrgico com desbridamento",
  "30402093": "Tumor benigno de conduto auditivo externo - exérese",
  "30403014": "Cauterização de membrana timpânica",
  "30403030": "Estapedectomia ou estapedotomia",
  "30403049": "Exploração e descompressão parcial do nervo facial intratemporal",
  "30403057": "Fístula perilinfática - fechamento cirúrgico",
  "30403065": "Glomus jugular - ressecção",
  "30403073": "Glomus timpânicus - ressecção",
  "30403081": "Mastoidectomia simples ou radical modificada",
  "30403090": "Ouvido congênito - tratamento cirúrgico",
  "30403103": "Paracentese do tímpano - miringotomia, unilateral - em consultório",
  "30403162": "Paracentese do tímpano, unilateral, em hospital - anestesia geral",
  "30403111": "Tímpano-mastoidectomia",
  "30403120": "Timpanoplastia com reconstrução da cadeia ossicular",
  "30403138": "Timpanoplastia tipo I - miringoplastia - unilateral",
  "30403146": "Timpanotomia exploradora - unilateral",
  "30403154": "Timpanotomia para tubo de ventilação - unilateral",
  "30404177": "Cirurgia para prótese auditiva percutânea ancorada no osso - unilateral (primeira implantação ou substituição)",
  "30404010": "Doença de Meniere - tratamento cirúrgico - descompressão do saco endolinfático ou \"shunt\"",
  "30404029": "Enxerto parcial intratemporal do nervo facial - do foramem estilo-mastóideo ao gânglio geniculado",
  "30404037": "Enxerto parcial intratemporal do nervo facial - do gânglio geniculado ao meato acústico interno",
  "30404045": "Enxerto total do nervo facial intratemporal",
  "30404053": "Exploração e descompressão total do nervo facial (transmastóideo, translabiríntico, fossa média)",
  "30404070": "Injeção de drogas intratimpânicas",
  "30404088": "Labirintectomia (membranosa ou óssea) - sem audição",
  "30404096": "Neurectomia vestibular para fossa média ou posterior",
  "30404100": "Neurectomia vestibular translabiríntica - sem audição",
  "30404185": "Neurotelemtria transoperatória do implante coclear unilateral",
  "30404150": "Procedimento cirúrgico de implante coclear unlitareal (primeira implantação ou substituição)",
  "30404126": "Ressecção do osso temporal",
  "30404169": "Substituição do imã do implante coclear unilateral",
  "30404134": "Tumor do nervo acústico - ressecção via translabiríntica ou fossa média",
  "30501016": "Abscesso ou hematoma de septo nasal - drenagem",
  "30501024": "Abscesso ou hematoma de septo nasal - drenagem sob anestesia geral",
  "30501040": "Alongamento de columela",
  "30501059": "Biópsia de nariz",
  "30501067": "Corneto inferior - cauterização linear - unilateral",
  "30501075": "Corneto inferior - infiltração medicamentosa (unilateral)",
  "30501083": "Corpos estranhos - retirada em consultório (nariz)",
  "30501091": "Corpos estranhos - retirada sob anestesia geral / hospital",
  "30501474": "Corpos estranhos - retirada sob anestesia geral / hospital (nariz) - por videoendoscopia",
  "30501113": "Epistaxe - cauterização (qualquer técnica)",
  "30501121": "Epistaxe - cauterização da artéria esfenopalatina com microscopia - unilateral",
  "30501482": "Epistaxe - cauterização da artéria esfenopalatina com microscopia - unilateral por videoendoscopia",
  "30501130": "Epistaxe - cauterização das artérias etmoidais com microscopia - unilateral",
  "30501148": "Epistaxe - ligadura das artérias etmoidais - acesso transorbitário - unilateral",
  "30501156": "Epistaxe - tamponamento  antero-posterior",
  "30501164": "Epistaxe - tamponamento anterior",
  "30501172": "Epistaxe - tamponamento antero-posterior sob anestesia geral",
  "30501180": "Exérese de tumor com abordagem craniofacial oncológica (tempo facial) pirâmide nasal",
  "30501199": "Exérese de tumor nasal por vídeo-endoscópico",
  "30501202": "Fechamento de fístula liquórica transnasal",
  "30501210": "Fístula liquórica - tratamento cirúrgico por vídeo-endoscópico intranasal",
  "30501229": "Fraturas dos ossos nasais - redução cirúrgica e gesso",
  "30501237": "Fraturas dos ossos nasais - redução incruenta e gesso",
  "30501245": "Imperfuração coanal - correção cirúrgica intranasal",
  "30501490": "Imperfuração coanal - correção cirúrgica intranasal por videoendoscopia",
  "30501253": "Imperfuração coanal - correção cirúrgica transpalatina",
  "30501261": "Ozena - tratamento cirúrgico",
  "30501504": "Ozena - tratamento cirúrgico por videoendoscopia",
  "30501270": "Perfuração do septo nasal - correção cirúrgica",
  "30501512": "Perfuração do septo nasal - correção cirúrgica por videoendoscopia",
  "30501288": "Polipectomia - unilateral",
  "30501296": "Reconstrução de unidade anatômica do nariz - por estágio",
  "30501300": "Reconstrução total de nariz - por estágio",
  "30501318": "Ressecção de tumores malignos transnasais",
  "30501326": "Rinectomia parcial",
  "30501334": "Rinectomia total",
  "30501342": "Rinoplastia reparadora",
  "30501350": "Rinosseptoplastia funcional",
  "30501520": "Rinosseptoplastia funcional por videoendoscopia",
  "30501369": "Septoplastia (qualquer técnica sem vídeo)",
  "30501539": "Septoplastia por videoendoscopia",
  "30501377": "Sinéquia nasal - ressecção unilateral - qualquer técnica",
  "30501385": "Tratamento cirúrgico da atresia narinária",
  "30501393": "Tratamento cirúrgico de deformidade nasal congênita",
  "30501407": "Tratamento cirúrgico do rinofima",
  "30501415": "Tratamento cirúrgico reparador do nariz em sela",
  "30501423": "Tratamento de deformidade traumática nasal",
  "30501431": "Tumor intranasal - exérese por rinotomia lateral",
  "30501440": "Tumor intranasal - exérese por via transnasal",
  "30501458": "Turbinectomia ou turbinoplastia - unilateral",
  "30501466": "Turbinoplastia por radiofrequência",
  "30502012": "Angiofibroma - ressecção transmaxilar e/ou transpalatina",
  "30502020": "Antrostomia maxilar intranasal",
  "30502292": "Antrostomia maxilar intranasal por videoendoscopia",
  "30502039": "Artéria maxilar interna - ligadura transmaxilar",
  "30502306": "Artéria maxilar interna - ligadura transmaxilar por videoendoscopia",
  "30502047": "Cisto naso-alveolar e globular - exérese",
  "30502063": "Descompressão transetmoidal do canal óptico",
  "30502071": "Etmoidectomia externa",
  "30502080": "Etmoidectomia intranasal",
  "30502314": "Etmoidectomia intranasal por videoendoscopia",
  "30502098": "Exérese de tumor com abordagem craniofacial oncológica seios...(tempo facial)",
  "30502101": "Exérese de tumor de seios paranasais por via endoscópica",
  "30502110": "Fístula oro-antral - tratamento cirúrgico",
  "30502128": "Fístula oronasal - tratamento cirúrgico",
  "30502136": "Maxilectomia incluindo exenteração de órbita",
  "30502144": "Maxilectomia parcial",
  "30502152": "Maxilectomia total",
  "30502160": "Pólipo antro-coanal de Killiam - exérese",
  "30502179": "Punção maxilar transmeática ou via fossa canina",
  "30502187": "Ressecção de tumor benigno",
  "30502195": "Seios paranasais - biópsia qualquer via",
  "30502217": "Sinusectomia frontal com retalho osteoplástico ou via coronal",
  "30502225": "Sinusectomia fronto-etmoidal por via externa",
  "30502209": "Sinusectomia maxilar - via endonasal",
  "30502322": "Sinusectomia maxilar - via endonasal por videoendoscopia",
  "30502233": "Sinusectomia maxilar - via oral (Caldwell-Luc)",
  "30502241": "Sinusectomia transmaxilar (Ermiro de Lima)",
  "30502250": "Sinusotomia esfenoidal",
  "30502349": "Sinusotomia esfenoidal por videoendoscopia",
  "30502268": "Sinusotomia frontal intranasal",
  "30502365": "Sinusotomia frontal intranasal com balão por videoendoscopia",
  "30502357": "Sinusotomia frontal intranasal por videoendoscopia",
  "30502276": "Sinusotomia frontal via externa"
  };
import { useEffect, useState } from "react";
import CheckBox from "../../components/Checkbox";
import SearchBar from "../../components/SearchBar";
import "./styles.css";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";

interface InputResponse {
  query: string;
  response: string;
}

const CheckBoxPage: React.FC = () => {
  const location = useLocation();
  // const { laudoText, checkboxInitial, patientName, newInputRequest } =
  //   location.state || {};
  const navigate = useNavigate();
  const { checkboxItems, setCheckboxItems, setCheckedItems, laudoText, initialCheckboxItems, patientName, newInputRequest, setPatientName, setInitialCheckboxItems, setLaudoText, setnewInputRequest } = useAppContext();
  //const [checkboxItems, setCheckboxItems] = useState<string[][]>([]);
  // const [checkedItems, setCheckedItems] = useState<
  //   Array<{ code: string; description: string; quantity: string }>
  // >([]);
  const [searchResults, setSearchResults] = useState<string[][]>([]);
  const [inputResponse, setInputResponse] = useState<Array<InputResponse>>([]);

  useEffect(() => {
    if (checkboxItems) {
      setCheckboxItems(checkboxItems);
    }
  }, [checkboxItems]);

  const handleCheckboxItemsAllTime: (items: string[][]) => void = (items) => {
    setCheckboxItems(items);
  };

  const handleCheckboxItemsUpdate = (item: string[]) => {
    setCheckboxItems((prevItems) => {
      const existingItemIndex = prevItems.findIndex(
        (prevItem) => prevItem[0] === item[0]
      );
      if (existingItemIndex === -1) {
        const updatedItems = [...prevItems, item];
        handleSearch(updatedItems);
        return updatedItems;
      } else {
        if (item[2] === prevItems[existingItemIndex][2]) {
          toast.error("Esse procedimento já está no pedido.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Slide,
          });
        }
      }
      return prevItems;
    });
  };

  const handleSearch = (filteredNames: string[][]) => {
    setSearchResults(filteredNames);
  };

  const handleCheckedItems = (
    checkedItems: Array<{
      code: string;
      description: string;
      quantity: string;
    }>,
    patientName: string,
    laudo: string,
    historico: string
  ) => {
    setCheckedItems(checkedItems);
    sendCheckedItems(checkedItems, patientName, laudo, historico);
  };

  const sendCheckedItems = async (
    checkedItems: Array<{
      code: string;
      description: string;
      quantity: string;
    }>,
    patientName: string,
    laudo: string,
    historico: string
  ) => {
    try {
      navigate("/downloadpdf", {
        state: {
          checkedItems,
          patientName: patientName,
          laudo,
          historico,
          checkboxInitial: initialCheckboxItems
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGoBackButton = () => {
    setCheckboxItems([]);
    setPatientName("");
    setnewInputRequest("");
    setLaudoText("");
    setInitialCheckboxItems([]);
    navigate("/novo-pedido");
  };

  return (
    <main className="checkbox-page__main-container">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Slide}
      />
      <div className="checkbox-page__container">
      <img
          id="arrow"
          src={`${process.env.PUBLIC_URL}/arrow.svg`}
          alt="arrow"
          style={{ width: "30px", height: "30px", marginTop: "20px" }}
          onClick={handleGoBackButton}
        />
        <h1 className="checkbox-page__header">Identificamos esses códigos:</h1>
        <SearchBar
          onSearch={handleSearch}
          onCheckboxItemsUpdate={handleCheckboxItemsUpdate}
        />
        <CheckBox
          texts={checkboxItems}
          onSendCheckedItems={handleCheckedItems}
          nome_paciente={patientName}
          laudo={laudoText}
          historico={newInputRequest}
          onItemsChange={handleCheckboxItemsAllTime}
        />
      </div>
    </main>
  );
};

export default CheckBoxPage;

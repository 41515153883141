import "./styles.css";
import React, { useState, useEffect, useRef, ChangeEvent } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { OpmeItens } from "./opmeItens";
import { CiTrash } from "react-icons/ci";
import { FiPlus, FiMinus } from "react-icons/fi";
import { useToken } from "../../services/api";
import { useAuth0 } from "@auth0/auth0-react";
import Select from "../../components/Select";
import { IoIosArrowDown } from "react-icons/io";

interface CheckedItem {
  code: string;
  description: string;
  quantity: string;
}

const OPMEPage: React.FC = () => {
  const location = useLocation();
  const {
    checkedItems,
    EditableResponse,
    patientName,
    informacoesAtualizadas,
    timestamp,
  } = location.state || {};
  const navigate = useNavigate();
  //const ROTA = "http://localhost:5000";
  const ROTA = "https://api.letsdoc.ai";
  //const LOCALHOST = "http://localhost:8000";

  const [selectedItems, setSelectedItems] = useState<
    { item: string; quantity: number }[]
  >([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isAddingCustomItem, setIsAddingCustomItem] = useState(false);
  const [customItemName, setCustomItemName] = useState("");
  const [customItemQuantity, setCustomItemQuantity] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const searchRef = useRef<HTMLDivElement>(null);
  const { user } = useAuth0();
  const EMAIL = user?.email;
  const [isDownloading, setIsDownloading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<string>("Selecione o plano");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedDownload, setSelectedDownload] = useState<string>(
    "Selecione o documento"
  );
  const [showOptionsPlan, setShowOptionsPlan] = useState<boolean>(false);
  const [showOptionsDownload, setShowOptionsDownload] =
    useState<boolean>(false);
  const [showSelectForDownload, setShowSelectForDownload] =
    useState<boolean>(false);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const handlePlanSelection = (plan: string) => {
    setSelectedPlan(plan);
    setShowOptionsPlan(false);
  };
  const handleDownloadSelection = (document: string) => {
    setSelectedFile(null);
    setSelectedDownload(document);
    setShowOptionsDownload(false);
    if (document === "PEDIDO_DE_CIRURGIA" || document === "OPME") {
      setShowSelectForDownload(true);
    } else {
      setShowSelectForDownload(false);
      setSelectedPlan("Selecione o plano");
    }
  };

  const plans = [
    "AMIL",
    "BRADESCO",
    "CAC",
    "CASSI",
    "EMBRATEL",
    "EVERCROSS",
    "GAMA",
    "GEAP",
    "GOLDEN_CROSS",
    "INTERMEDICA",
    "LEVE SAÚDE",
    "MUTUA",
    "OMINT",
    "PASAVALE",
    "PETROBRÁS",
    "SULAMERICA",
    "UNIMED",
  ];

  const plansForOPME = [
    "AMIL",
    "BRADESCO",
    "LEVE SAÚDE",
    "PETROBRÁS",
    "SULAMERICA",
    "UNIMED",
  ];

  const downloads_types = ["RESUMO", "OPME"];

  let updates: any = extrairInformacoes(EditableResponse);

  const getToken = () => {
    const token = localStorage.getItem("authToken");
    return token;
  };
  const token = getToken();
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target as Node)
      ) {
        setIsSearchOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSelectItem = (item: string) => {
    if (!selectedItems.some((selected) => selected.item === item)) {
      setSelectedItems([...selectedItems, { item, quantity: 1 }]);
    }
    setIsSearchOpen(false);
  };

  const handleRemoveItem = (item: string) => {
    setSelectedItems(
      selectedItems.filter((selected) => selected.item !== item)
    );
  };

  const handleQuantityChange = (item: string, increment: boolean) => {
    setSelectedItems(
      selectedItems
        .map((selected) =>
          selected.item === item
            ? {
                ...selected,
                quantity: selected.quantity + (increment ? 1 : -1),
              }
            : selected
        )
        .filter((selected) => selected.quantity > 0)
    );
  };

  const filteredOpmes = OpmeItens.filter((item) =>
    item.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleGoHomeButton = () => {
    navigate("/novo-pedido");
  };

  const handleAddElement = () => {
    setIsAddingCustomItem(true);
    setIsSearchOpen(false);
  };

  const handleCustomItemChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCustomItemName(event.target.value);
  };

  const handleAddCustomItem = () => {
    if (customItemName.trim()) {
      setSelectedItems([
        ...selectedItems,
        { item: customItemName.trim(), quantity: 1 },
      ]);
      setCustomItemName("");
      setIsAddingCustomItem(false);
    }
  };

  const handleCancelAddCustomItem = () => {
    setCustomItemName("");
    setIsAddingCustomItem(false);
  };

  const handleIncreaseCustomItemQuantity = () => {
    setCustomItemQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecreaseCustomItemQuantity = () => {
    setCustomItemQuantity((prevQuantity) => Math.max(prevQuantity - 1, 1));
  };

  const handleFileChange = (file: File) => {
    setSelectedFile(file);
  };

  // Função para corrigir o formato dos; procedimentos
  function formatarProcedimentos(procedimentos: string): string {
    // Dividir cada linha de procedimento em um array
    const linhasProcedimentos = procedimentos.split("\n");

    // Percorrer cada linha, corrigindo o formato
    const procedimentosCorrigidos = linhasProcedimentos.map((linha) => {
      const match = linha.match(/^(\d+)\s*-?\s*x?(\d+)\s*-?\s*(.*)$/);

      if (match) {
        const codigo = match[1];
        const quantidade = match[2];
        const descricao = match[3];
        return `${codigo} x${quantidade} ${descricao}`; // Remover o hífen antes da descrição
      } else {
        return linha; // Se o formato estiver incorreto, retornar a linha original
      }
    });

    // Retornar os procedimentos corrigidos como uma string única
    return procedimentosCorrigidos.join("\n");
  }

  interface Result {
    pacient_name?: string;
    pedido_cirurgia?: string;
    // outras propriedades
  }

  function extrairInformacoes(texto: string) {
    let result: Result = {};
    const regexNomePaciente = /Nome do paciente:\s*(.*)/;
    const regexDescricaoPaciente =
      /(?<=Descrição do paciente:\n)([\s\S]*?)(?=\n-+)/;
    const regexProcedimentos = /(?<=Procedimentos:\n)([\s\S]*?)(?=\n-+)/;
    const regexCids = /(?<=CID's:\n)([\s\S]*?)(?=\n-+)/;
    const regexDataEmissao = /(?<=Data de emissão:\n)(.*)/;
    const regexNomeHospital = /Hospital:\s*(.*)/;

    let nomePaciente = texto.match(regexNomePaciente)?.[1]?.trim() || "";
    let descricaoPaciente =
      texto.match(regexDescricaoPaciente)?.[0]?.trim() || "";
    const procedimentos = texto.match(regexProcedimentos)?.[0]?.trim() || "";
    let cids = texto.match(regexCids)?.[0]?.trim() || "";
    const dataEmissao = texto.match(regexDataEmissao)?.[0]?.trim() || "";
    let hospital = texto.match(regexNomeHospital)?.[1]?.trim() || "";

    if ("descricaoPaciente" in informacoesAtualizadas) {
      descricaoPaciente = informacoesAtualizadas.descricaoPaciente;
    }
    if ("cids" in informacoesAtualizadas) {
      cids = informacoesAtualizadas.cids;
    }
    if ("hospital" in informacoesAtualizadas) {
      hospital = informacoesAtualizadas.hospital;
    }

    const procedimentosCorrigidos = formatarProcedimentos(procedimentos);

    const pedido_cirurgia = `
    Aqui está seu pedido de cirurgia:
    -------------------------------------------------
    Nome do paciente:
    ${nomePaciente}
    -------------------------------------------------
    Descrição do paciente:
    ${descricaoPaciente}
    -------------------------------------------------
    Procedimentos:
    ${procedimentosCorrigidos}
    -------------------------------------------------
    CID's:
    ${cids}
    -------------------------------------------------
    Data de emissão:
    ${dataEmissao}
    -------------------------------------------------
    Hospital:
    ${hospital}
    -------------------------------------------------`;

    result["pedido_cirurgia"] = pedido_cirurgia.trim();
    return result;
  }

  const createRequestInit = (body: object) => ({
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });

  const downloadPDF = async (
    url: string,
    requestInit: RequestInit,
    fileName: string
  ) => {
    const response = await fetch(url, requestInit);
    const blob = await response.blob();
    const urlBlob = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = urlBlob;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadPDF = async () => {
    setIsDownloading(true);
    let template = "";
    switch (selectedPlan) {
      case "BRADESCO":
        template = "bradesco_opme";
        break;
      case "AMIL":
        template = "amil_opme";
        break;
      case "UNIMED":
        template = "unimed_opme";
        break;
      case "SULAMERICA":
        template = "sulamerica_opme";
        break;
      case "PETROBRÁS":
        template = "petrobras_opme";
        break;
      case "LEVE SAÚDE":
        template = "levesaude_opme";
        break;
      default:
        template = "generic_template";
    }
    const transformedOpmeItems = selectedItems.map((opme) => [
      opme.item,
      opme.quantity.toString(),
    ]);
    const opme_list = selectedItems.map((opme) => opme.item);
    const procedureDescriptions = checkedItems.map(
      (procedure: CheckedItem) => procedure.description
    );

    const requestInit2 = createRequestInit({
      email: EMAIL,
      template_type: template,
      procedures: procedureDescriptions,
      opme_data: transformedOpmeItems,
      pacientName: patientName,
    });

    const template_file = "selectedFile";

    const emailToSend = EMAIL || "";

    const pedido_de_cirurgia =
      Object.keys(informacoesAtualizadas).length > 0
        ? updates.pedido_cirurgia
        : EditableResponse;

    const materiais_opme = opme_list;

    const requestInit3 = createRequestInit({
      email: emailToSend,
      pedido_de_cirurgia: pedido_de_cirurgia,
      materiais_opme: materiais_opme,
    });

    try {
      if (selectedDownload === "OPME") {
        await downloadPDF(
          `${ROTA}/chat/exportOPMEPDF`,
          requestInit2,
          `${patientName}_opme.pdf`
        );
      } else {
        await downloadPDF(
          `${ROTA}/chat/resumo`,
          requestInit3,
          `${patientName}_resumo.pdf`
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsDownloading(false);
    }
  };

  const handleDownloadResumo = async () => {
    setIsDownloading(true);
    const transformedOpmeItems = selectedItems.map((opme) => [
      opme.item,
      opme.quantity.toString(),
    ]);
    const opme_list = selectedItems.map((opme) => opme.item);
    const procedureDescriptions = checkedItems.map(
      (procedure: CheckedItem) => procedure.description
    );

    const pedido_de_cirurgia =
      Object.keys(informacoesAtualizadas).length > 0
        ? updates.pedido_cirurgia
        : EditableResponse;

    const materiais_opme = opme_list;

    const requestInitResumo = createRequestInit({
      email: EMAIL,
      pedido_de_cirurgia: pedido_de_cirurgia,
      materiais_opme: materiais_opme,
    });

    try {
      await downloadPDF(
        `${ROTA}/chat/resumo`,
        requestInitResumo,
        `${patientName.replace(/\s+/g, "_")}_resumo.pdf`
      );
    } catch (error) {
      console.error("Erro ao baixar o resumo:", error);
    } finally {
      setIsDownloading(false);
    }
  };

  const handleDownloadOPME = async () => {
    setIsDownloading(true);
    let template = "";
    switch (selectedPlan) {
      case "BRADESCO":
        template = "bradesco_opme";
        break;
      case "AMIL":
        template = "amil_opme";
        break;
      case "UNIMED":
        template = "unimed_opme";
        break;
      case "SULAMERICA":
        template = "sulamerica_opme";
        break;
      case "PETROBRÁS":
        template = "petrobras_opme";
        break;
      case "LEVE SAÚDE":
        template = "levesaude_opme";
        break;
      default:
        template = "generic_template";
    }

    const transformedOpmeItems = selectedItems.map((opme) => [
      opme.item,
      opme.quantity.toString(),
    ]);
    const opmeList = selectedItems.map((opme) => opme.item);
    const procedureDescriptions = checkedItems.map(
      (procedure: CheckedItem) => procedure.description
    );

    const requestInit = createRequestInit({
      email: EMAIL,
      template_type: template,
      procedures: procedureDescriptions,
      opme_data: transformedOpmeItems,
      pacientName: patientName,
    });

    try {
      await downloadPDF(
        `${ROTA}/chat/exportOPMEPDF`,
        requestInit,
        `${patientName}_opme.pdf`
      );
    } catch (error) {
      console.error("Erro ao baixar o PDF OPME:", error);
    } finally {
      setIsDownloading(false);
    }
  };

  const getUpdate = async () => {
    const requestInit4 = createRequestInit({
      timestamp: timestamp,
      updates: updates,
    });
    if (Object.keys(informacoesAtualizadas).length > 0) {
      const url4 = `${ROTA}/chat/updateLog`;
      const response4 = await fetch(url4, requestInit4);
    }
  };

  useEffect(() => {
    updates["opme_list"] = selectedItems;
  }, [selectedItems]);

  useEffect(() => {
    updates["opme_list"] = selectedItems;
  }, [selectedItems]);

  useEffect(() => {
    getUpdate();
  }, [EditableResponse, selectedItems]);

  const handleGoBackButton = () => {
    navigate("/downloadpdf");
  };

  return (
    <>
      <main className="opme-page__main-container">
        <div className="opme-hero">
          <div className="opme-page_navigation">
            <button className="opme-page_home" onClick={handleGoHomeButton}>
              <span className="material-symbols-outlined">home</span>
            </button>
          </div>
          <div className="test-opme">
            <h1 className="opme-page__header">Selecione os códigos OPME:</h1>
            <div className="opme-search-bar">
              <div className="opme-search-icon">
                <img
                  id="search"
                  src={`${process.env.PUBLIC_URL}/search.svg`}
                  alt="search"
                  onClick={() => setIsSearchOpen(true)}
                />
              </div>
              <input
                type="text"
                placeholder="Pesquisar OPME..."
                value={searchTerm}
                onChange={handleSearchChange}
                onClick={() => setIsSearchOpen(true)}
                className="opme-search-input"
              />
            </div>
          </div>
          <div className="opme-search-wrapper" ref={searchRef}>
            {isSearchOpen && (
              <ul className="opme-search-results">
                <li className="opme-add-element" onClick={handleAddElement}>
                  <div className="icon-add-element">
                    <FiPlus />
                  </div>
                  Adicionar seu OPME
                </li>

                {filteredOpmes.length > 0 ? (
                  filteredOpmes.map((item, index) => (
                    <li
                      key={index}
                      className="opme-search-item"
                      onClick={() => handleSelectItem(item)}
                    >
                      {item}
                    </li>
                  ))
                ) : (
                  <li>Sem Resultados</li>
                )}
              </ul>
            )}
          </div>
          {isAddingCustomItem && (
            <div className="opme-custom-item-input">
              <input
                className="opme-custom-input"
                type="text"
                placeholder="Digite o nome do item"
                value={customItemName}
                onChange={handleCustomItemChange}
              />
              <button
                className="opme-quantity-button-add"
                onClick={handleIncreaseCustomItemQuantity}
              >
                <FiPlus />
              </button>
              <button
                className="opme-quantity-button-add"
                onClick={() =>
                  customItemQuantity === 1
                    ? handleCancelAddCustomItem()
                    : handleDecreaseCustomItemQuantity()
                }
              >
                {customItemQuantity === 1 ? <CiTrash /> : <FiMinus />}
              </button>

              <span>{customItemQuantity}</span>
              <button
                className="opme-quantity-button-add"
                onClick={handleAddCustomItem}
              >
                Confirmar
              </button>
            </div>
          )}
          <div className="opme-page">
            <div className="opme-page_content-wrapper">
              {selectedItems.length > 0 && (
                <div className="opme-selected-items">
                  <ul>
                    {selectedItems.map((selected, index) => (
                      <li key={index} className="opme-selected-item">
                        <label>{selected.item}</label>
                        <div className="opme-quantity-controls">
                          <button
                            className="opme-quantity-button"
                            onClick={() =>
                              selected.quantity === 1
                                ? handleRemoveItem(selected.item)
                                : handleQuantityChange(selected.item, false)
                            }
                          >
                            {selected.quantity === 1 ? (
                              <CiTrash />
                            ) : (
                              <FiMinus />
                            )}
                          </button>
                          <button
                            className="opme-quantity-button"
                            onClick={() =>
                              handleQuantityChange(selected.item, true)
                            }
                          >
                            <FiPlus />
                          </button>
                          <span className="opme-quantity-display">
                            {selected.quantity}
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          <div className="opme-page__surgery-button">
            <button
              className="opme-page_choose-button"
              onClick={() => setShowModal(true)}
            >
              Escolher plano de saúde
            </button>
          </div>
          {showModal && (
            <div className="modal-overlay">
              <div className="modal">
                <button
                  className="modal-close-button"
                  onClick={() => setShowModal(false)}
                >
                  &times;
                </button>
                <h1>Escolha o plano de saúde</h1>
                <div
                  className="opme-page__selected"
                  onClick={() => setShowOptions(!showOptions)}
                >
                  {selectedPlan}
                  <IoIosArrowDown color="#4B73FF" />
                </div>
                <div
                  className={`opme-page__options-container ${
                    showOptions ? "active" : ""
                  }`}
                >
                  {[
                    "AMIL",
                    "BRADESCO",
                    "LEVE SAÚDE",
                    "PETROBRÁS",
                    "SULAMERICA",
                    "UNIMED",
                  ].map((plan) => (
                    <div
                      key={plan}
                      className="opme-page__option"
                      onClick={() => handlePlanSelection(plan)}
                    >
                      <input
                        type="radio"
                        id={plan}
                        name="category"
                        checked={selectedPlan === plan}
                        readOnly
                      />
                      <label htmlFor={plan}>{plan}</label>
                    </div>
                  ))}
                </div>
                <button
                  className="opme-page_download-button"
                  disabled={selectedPlan === "Selecione o plano"}
                  onClick={handleDownloadOPME}
                >
                  {isDownloading ? (
                    <div className="loading-circle"></div>
                  ) : (
                    "Download OPME"
                  )}
                </button>
              </div>
            </div>
          )}
          <button
            className="opme-page_resumo-button"
            onClick={handleDownloadResumo}
          >
            {isDownloading ? <div className="loading-circle"></div> : "Resumo"}
          </button>
        </div>
      </main>
    </>
  );
};

export default OPMEPage;

interface NoOrdersProps {
    name: string;
}

const NoOrders: React.FC<NoOrdersProps> = ({name}) => {
  return (
    <p
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      {`Não há ${name}.`}
    </p>
  );
};

export default NoOrders;

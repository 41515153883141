import React, { useEffect, useState } from "react";
import "./styles.css";
import Header from "../../components/Header";
import { useLocation } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { procedures } from "../../components/SearchBar/proceduresData";
import { useNavigate } from "react-router-dom";
import { useToken } from "../../services/api";

interface InputResponse {
  query: string;
  response: string;
}

const ExportarPedido: React.FC = () => {
  const location = useLocation();
  const {
    newResponse,
    nome_paciente,
    user_historico,
    user_laudo,
    procedimentos_modelo,
  } = location.state as {
    newResponse: InputResponse[];
    user_historico: string;
    user_laudo: string;
    procedimentos_modelo: string;
    nome_paciente: string;
  };

  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedResponse, setEditedResponse] = useState<string[]>(
    newResponse.map((item) => item.response)
  );
  const navigate = useNavigate();

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const [token, setToken] = useState<string | null>(null);
  const { getToken } = useToken();

  useEffect(() => {
    const fetchToken = async () => {
      const fetchedToken = await getToken();
      setToken(fetchedToken);
    };
    fetchToken();
  }, [getToken]);
  const extractData = (text: string): string => {
    const regex_description = /(\d{8})/g;
    const matches_description = text.match(regex_description);

    const regex_quantity = /x\d+/g;
    const matches_quantity = text.match(regex_quantity);
    const quantity = matches_quantity || "";

    let result = "";

    if (matches_description) {
      for (let i = 0; i < matches_description.length; i++) {
        const code = matches_description[i];
        if (procedures[code as keyof typeof procedures]) {
          const desc = procedures[code as keyof typeof procedures];
          const qty = quantity[i];
          if (desc && qty) {
            result += `${code} ${desc} ${qty} `;
          }
        }
      }
    }

    return result.trim();
  };

  const handleResponseChange = (index: number, newText: string) => {
    setEditedResponse((prev) => {
      const updated = [...prev];
      updated[index] = newText;
      return updated;
    });
  };

  const reset = async () => {
    await fetch("https://api.letsdoc.ai/chat/reset", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    //window.location.reload();
  };

  const handleLogClick = async () => {
    const extractedData = editedResponse
      .map((response) => extractData(response))
      .join(" ");
    const pedido_cirurgia = `${editedResponse}`;
    setLoading(true);
    const requestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_historico: user_historico,
        user_laudo: user_laudo,
        procedimentos_modelo: procedimentos_modelo,
        procedimentos_validados: extractedData,
        pedido_cirurgia: pedido_cirurgia,
      }),
    };

    try {
      const url = "https://api.vytalia.cogg.ai/chat/log";
      const response = await fetch(url, requestInit);

      // Check if the log was saved successfully before downloading the PDF
      if (response.ok) {
        await handleDownloadPDF(pedido_cirurgia);
        setLoading(false);
        reset();
        console.log(reset);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadPDF = async (pedido_cirurgia: string) => {
    const requestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        pedido_de_cirurgia: pedido_cirurgia,
        template_type: "mediservice-bradesco",
      }),
    };

    try {
      const url = "https://api.vytalia.cogg.ai/chat/exportPDF";
      const response = await fetch(url, requestInit);
      const blob = await response.blob();
      const urlBlob = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = urlBlob;
      link.download = "pedido_de_cirurgia.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <main className="main-container">
      <Header />
      <div className="edit-box">
        {newResponse &&
          newResponse.map((response, index) => (
            <div key={index} className="response-container">
              <div className="response-item">
                <button className="edit-button" onClick={handleEditClick}>
                  {isEditing ? "Salvar" : "Editar"}
                </button>
                <div className="response-text">
                  {isEditing ? (
                    <textarea
                      value={editedResponse[index]}
                      onChange={(e) =>
                        handleResponseChange(index, e.target.value)
                      }
                      className="edit-textarea"
                    />
                  ) : (
                    editedResponse[index]
                      .split("\n")
                      .map((paragraph, paragraphIndex) => {
                        if (paragraph.startsWith("###")) {
                          return (
                            <h3 key={paragraphIndex} className="response-title">
                              {paragraph.replace("###", "").trim()}
                            </h3>
                          );
                        } else if (paragraph.startsWith("-")) {
                          return (
                            <li
                              key={paragraphIndex}
                              className="response-list-item"
                            >
                              {paragraph}
                            </li>
                          );
                        } else {
                          const formattedParagraph = paragraph
                            .split("**")
                            .map((chunk, chunkIndex) => {
                              return chunkIndex % 2 === 0 ? (
                                <span key={chunkIndex}>{chunk}</span>
                              ) : (
                                <b key={chunkIndex}>{chunk}</b>
                              );
                            });

                          return (
                            <p
                              key={paragraphIndex}
                              className="response-paragraph"
                            >
                              {formattedParagraph}
                            </p>
                          );
                        }
                      })
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
      <button className="button-arquivo" onClick={handleLogClick}>
        Gerar arquivo
        {loading && <AiOutlineLoading3Quarters className="loading-icon" />}
      </button>
    </main>
  );
};

export default ExportarPedido;

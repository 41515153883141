export const OpmeItens = [
    "Dreno a vácuo para subcutâneo",
    "Kit duroplastia sintética",
    "Hemostático absorvível de gelatina composto por pele de porco purificada",
    "Compressa neurocirúrgica do tipo cotonoide com filamento rádio-opaco",
    "Laser de diodo + Kit introdutor",
    "Kit de eletrodos para neuromonitorização (deverá ser realizada por médico em acordo com resoluções do CFM)",
    "Dreno a vácuo para subcutâneoKit hemostasia/curativo – Gelfoam",
    "Cânula traqueal de Shiley",
    "Laser de CO201",
    "76126641 Lâmina de microdebridação/curva",
    "Curativo estéril de filme transparente ou película protetora adesivo",
    "Capa de microscópio",
    "Dreno a vácuo para subcutâneo",
    "Kit reconstrução craniana",
    "77510909 Kit cânula para cirurgia a laser",
    "Laser de diodo ou CO2",
    "Kit Motor de Drill",
    "Kit hemostático absorvível – Gelfoam",
    "Curativo estéril de filme transparente ou película protetora adesivoKit hemostasia/curativo – Gelfoam",
    "Cera hemostática para osso",
    "Aspirador cautério",
    "Shaver para microcirurgia de laringe",
    "Molde laríngeo",
    "Cola biológica",
    "Capa de microscópio       ",
    "KIT Motor de Drill",
    "Kit hemostasia",
    "Capa de endoscópio",
    "40 – 70 graus01",
    "Prótese de reconstrução de cadeia ossicular parcial ou total (titânio, teflon) de acordo com a necessidade após revisão de cadeia ossicular",
    "Laser de CO2",
    "Kit cânula para cirurgia a laser de adenoidectomia e amigdalectomia",
    "Tubo de curta ou longa duração para timpanotomia",
    "Kit de Brocas Cortantes (01) e Kit de Brocas Diamantadas (01)",
    "Kit Motor de Dril (Quando for necessária a canalplastia)",
    "Kit Motor de Drill*",
    "Goretex",
    "Cautério bipolar",
    "Kit de eletrodos para neuromonitorização (deverá ser realizada por médico em acordo com resoluções do CFM)*01",
    "Capa de microscópio cirúrgico",
    "Lâmina de microdebridação/reta",
    "Kit Motor de Drill (Quando for necessária a canalplastia)",
    "Coblation para laringe",
    "Kit hemostasia/curativo – Gelfoam01",
    "Ponteira de radiofrequência geradora de plasma com temperatura alvo de 40 – 70 graus",
    "Kit de Brocas Cortantes (05) e Kit de Brocas Diamantadas (05)",
    "Kit de hemostasia",
    "Kit Motor de Drill (quando for necessária a canalplastia)",
    "Kit cânula para cirurgia a laser de laringe",
    "Prótese vocal",
    "Microdebridador curvo/reto ou ponteira de radiofrequência geradora de  plasma com temperatura alvo de 40 – 70 graus",
    "Kit de eletrodos para neuromonitorização",
    "Kit hemostasia/curativo – Gelfoam",
    "Ponteira de radiofrequência longa para palatoplastia",
    "Toxina botulínica tipo A 01 ampola",
    "Ponteira Coblation – Procise Max",
    "Kit de brocas cortantes (01) e Kit de brocas diamantadas (01)",
    "Lâmina de  nicrodebridação/curva",
    "Campo cirúrgico adesivo",
    "Kit de Brocas Cortantes (01) e Kit de Brocas Diamantadas (01)*",
    "Kit de eletrodos para neuromonitorização",
    "Kit Brocas Cortantes pequenas (02)",
    "Prótese de estapedectomia (titânio, teflon) de acordo com a necessidade após revisão de cadeia ossicular",
    "Kit de Brocas Cortantes (05) e  Kit de Brocas Diamantadas (05)",
    "Ponteira de radiofrequência geradora de plasma com temperatura alvo de",
    "Sistema de Aspirador Ultrassônico",
    "Kit de Brocas Cortantes (03) e Kit de Brocas Diamantadas (02)",
    "Sistema de implante coclear – processador de som e componente interno",
    "Ponteira coblation Procise Max",
    "Aspirador coagulador",
    "Campo cirúrgico adesivo",
    "Aquaplast",
    "Splints nasais",
    "Ponteira cauterio de colorado",
    "Lamina de shaver reta",
    "Lamina de shaver angulada",
    "Kit naso",
    "Endoscrub",
    "Tampão de merocel",
    "Bleed stp 3g",
    "Tubo de ventilação de shepard",
    "Tubo de ventilação de longa duração",
]
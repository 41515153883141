import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth0 } from "@auth0/auth0-react";
import "./styles.css";
import { FaRegFolderOpen } from "react-icons/fa";

interface UploadFileProps {
  folder: string;
  extension: string;
  errorMessage: string;
  successMessage: string;
  title: string;
}

const UploadFile: React.FC<UploadFileProps> = ({
  folder,
  extension,
  errorMessage,
  successMessage,
  title,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [isUploaded, setIsUploaded] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [nomeDoArquivo, setNomeDoArquivo] = useState("Buscando...");
  const { user } = useAuth0();
  const EMAIL = user?.email;
  //const ROTA = "http://localhost:5000";
  const ROTA = "https://api.letsdoc.ai";

  const getToken = () => {
    const token = localStorage.getItem("authToken");
    return token;
  };

  const token = getToken();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFile = e.target.files[0];
      setFile(selectedFile);

      const ext = selectedFile.name.split(".").pop()?.toLowerCase();

      if (ext && extension.includes(ext)) {
        setIsUploaded(true);
      } else {
        setIsUploaded(false);
        toast.error(errorMessage);
        e.target.value = "";
      }
    }
  };

  const handleConfirmClick = () => {
    toast.success(successMessage);
  };

  const createRequestInit = (body: any) => ({
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, 
    },
    body: body,
  });

  const postResume = async () => {
    setIsDownloading(true);
    let formData = new FormData();
    const template_file = file;
    const emailToSend = EMAIL || "";

    formData.append("email", emailToSend);
    formData.append("folder", folder);

    if (template_file) {
      formData.append("template_file", template_file);
    }

    const requestInit3 = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`, 
      },
      body: formData,
    };

    const url3 = `${ROTA}/chat/exportRESUMO`;
    const response3 = await fetch(url3, requestInit3);
    setIsDownloading(false);
    if (response3.ok) {
      handleConfirmClick();
    }
  };

  const getFILENAME = async () => {
    const emailToSend = EMAIL || "";

    if (!emailToSend) {
      console.error("O email do usuário é obrigatório.");
      return;
    }

    const requestGet = createRequestInit(JSON.stringify({
      folder: folder,
      email: emailToSend,
    }))

    const url_get = `${ROTA}/chat/getFileName`;

    try {
      const responseGet = await fetch(url_get, requestGet);

      if (!responseGet.ok) {
        throw new Error(`Erro na requisição: ${responseGet.status}`);
      }

      const data = await responseGet.json();
      setNomeDoArquivo(data.file);
    } catch (error) {
      console.error("Erro ao buscar o nome do arquivo:", error);
    }
  };

  useEffect(()=>{
    getFILENAME();
  }, [])

  return (
    <div className="uploadfile-content">
      <p>{title}</p>
      <div className="uploadfile-select">
        <label
          className="custom-file-upload"
          onClick={(e) => e.stopPropagation()}
        >
          <FaRegFolderOpen size={24} />

          <input
            id="file-upload"
            type="file"
            accept={extension === "pdf" ? ".pdf" : ".png,.jpg,.jpeg"}
            onChange={handleFileChange}
            className="hidden-input"
          />
        </label>
        <div className="uploadfile-text">
          {file ? file.name : nomeDoArquivo}
        </div>
      </div>
      {isUploaded && (
        <button className="confirm" onClick={postResume}>
          {isDownloading ? (
            <div className="loading-circle"></div>
              ) : (
                "Confirmar"
              )}
        </button>
      )}
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} closeOnClick={true} pauseOnHover={true} draggable={true} className="custom-toast"/>
    </div>
  );
};

export default UploadFile;

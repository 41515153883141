import React, { useState, useEffect, useRef, ChangeEvent } from "react";
import "./styles.css";


interface PacienteProps {
    name: string;
    click: () => void;
}

const PatientNameOrder: React.FC<PacienteProps> = ({name, click}) => {

  return (
    <div onClick={() => click()} className="patient_name_order_container">
        <div className="patient_name_order_content">
            <p>{name}</p>    
              <span className="material-symbols-outlined">chevron_right</span>
            </div>  
        <hr/>
    </div>
  );
};

export default PatientNameOrder;
